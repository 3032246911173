import { FC, useEffect, useState } from "react";
import { CiCirclePlus } from "react-icons/ci";
import { MdInfoOutline } from "react-icons/md";
import { RiDeleteBin3Line } from "react-icons/ri";
import ReactDatePicker from "../../../shared/components/reactDatePicker/ReactDatePicker";
import { TooltipComponent } from "../../../shared/components/tooltip/Tooltip";
import RequiredAsterisk from "../../../shared/components/ui/RequiredAsterisk";
import {
  formatDateSlashFullYear,
  revertToDateType,
} from "../../../shared/utils/formatDate";
import { timeOptions } from "../../constants/constantValues";
import {
  initBAProgramInfo,
  initSummerProgram,
} from "../../constants/initialState";
import {
  IElementarySchool,
  IOptionN,
} from "../../types/ICenterSetup.interface";
import {
  IPartTimeSessionsClassDetailed,
  IProgramClassrooms,
} from "../../types/IClassroomSetup.interface";
import TimeDropdown from "../dropdowns/TimeDropdown";
import InputField from "../inputsFields/InputField";
import ReactSelect from "../reactSelect/ReactSelect";
import { FiEdit } from "react-icons/fi";
import { PiCalendarPlus } from "react-icons/pi";

const numOfClassroomsOptions: IOptionN[] = [
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
];
const tooltips = {
  baProgramTitle: {
    id: "baProgramTitle",
    content: `<p>If you have school age children (Before School - After School Programs), select Yes</p>`,
  },
  elementarySchool: {
    id: "elementarySchool",
    content: `<p>For your School Age children (Before School - After School Programs), list the elementary schools your center accepts children from.</p>`,
  },
  summerStartDate: {
    id: "summerStartDate",
    content: `<p>Please choose the Monday of the week the Summer Program begins - even if it begins in the middle of the week.</p>`,
  },
  summerEndDate: {
    id: "summerEndDate",
    content: `<p>End date will be calculated. It will end on a Friday</p>`,
  },
  summerWeeks: {
    id: "summerWeeks",
    content: `<p>Please choose how many weeks the Summer Program will last. If it ends in the middle of the week, make sure you include that week.</p>`,
  },
  summerActivity: {
    id: "summerActivity",
    content: `<p>Here you can identify different programs during the Summer. For example, Math Club, English Club, Chess Club etc. You will then be able to assign children to each program.</p>`,
  },
  summerClassrooms: {
    id: "summerClassrooms",
    content: `<p></p>`,
  },
};
interface Props {
  hasBAProgram: boolean;
  hasSummerProgram: boolean;
  baProgramInfo: typeof initBAProgramInfo;
  summerProgramInfo: typeof initSummerProgram;
  specialClassroomsErrors: string[];
  toggleBAProgram: () => void;
  toggleSummerProgram: () => void;
  handleInputBaProgram: (
    name: keyof typeof initBAProgramInfo,
    value: string | number,
    subKey?:
      | keyof IPartTimeSessionsClassDetailed
      | keyof IProgramClassrooms
      | keyof IElementarySchool,
    index?: number
  ) => void;
  handleInputSummerProgram: (
    name: keyof typeof initSummerProgram,
    value: string | number,
    subKey?: keyof IProgramClassrooms,
    index?: number
  ) => void;
  addElementarySchool: () => void;
  removeElementarySchool: (index: number) => void;
  addActivity: () => void;
  removeActivity: (index: number) => void;
  handleSaveBAProgram: () => void;
  handleCancelProgram: () => void;
}

const BeforeAfterSchoolProgram: FC<Props> = ({
  hasBAProgram,
  hasSummerProgram,
  baProgramInfo,
  summerProgramInfo,
  specialClassroomsErrors,
  toggleBAProgram,
  toggleSummerProgram,
  handleInputBaProgram,
  handleInputSummerProgram,
  addElementarySchool,
  removeElementarySchool,
  addActivity,
  removeActivity,
  handleSaveBAProgram,
  handleCancelProgram,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);

  const validateRequiredFields = () => {
    if (specialClassroomsErrors?.length > 0) {
      return true;
    }
    // if (!hasBAProgram && !hasSummerProgram) {
    //   return true; // If neither program is selected, validation is complete
    // }
    if (hasBAProgram) {
      const isBAProgramInvalid =
        !baProgramInfo.numberOfClassrooms || // Check if the number of classrooms is set
        !baProgramInfo.classrooms?.length || // Ensure classrooms array is populated
        baProgramInfo.classrooms?.some(
          (classroom) => !classroom.classroomName?.trim()
        ) || // Ensure each classroom name is filled
        baProgramInfo.partTimeSlots?.some(
          (slot) => !slot.name || !slot.startTime?.trim() || !slot.endTime?.trim() // Check each slot for valid startTime and endTime
        ) ||
        baProgramInfo.elementarySchools?.some((school) => !school.schoolName?.trim());

      if (isBAProgramInvalid) return true;
    }

    if (hasSummerProgram) {
      const isSummerProgramInvalid =
        !summerProgramInfo.summerStartDate || // Ensure start date is set
        !summerProgramInfo.numberOfWeeks || // Ensure number of weeks is set
        // !summerProgramInfo.activities.length || // Ensure activities array is not empty
        summerProgramInfo.activities?.some((activity) => !activity?.trim()) || // Ensure each activity name is filled
        !summerProgramInfo.numberOfClassrooms || // Check if number of classrooms is set
        !summerProgramInfo.classrooms?.length || // Ensure classrooms array is populated
        summerProgramInfo.classrooms?.some(
          (classroom) => !classroom.classroomName?.trim()
        ); // Ensure each classroom name is filled

      if (isSummerProgramInvalid) return true;
    }

    return false; // Return false if all validations pass
  };

  const handleSaveClick = () => {
    handleSaveBAProgram();
    setIsEdit(false);
  };

  const handleCancelClick = () => {
    handleCancelProgram();
    setIsEdit(false);
  };

  const handleBaProgramTitle = (index: number) => {
    switch (index) {
      case 0:
        return "Before School";
      case 1:
        return "After School";
      case 2:
        return "Before/After School";
      default:
        break;
    }
  };

  useEffect(() => {
    setIsSaveDisabled(validateRequiredFields());
  }, [
    hasBAProgram,
    hasSummerProgram,
    baProgramInfo,
    summerProgramInfo,
    specialClassroomsErrors,
  ]);

  return (
    <>
      <div className="mb-6 bg-statusColor text-white px-5 py-3 rounded-lg font-semibold text-lg">
        Before we get to Classroom-Essentials, we just need to get the following
        critical information.
      </div>
      <div className="bg-white p-6 rounded-xl mb-6">
        <div>
          <div className="flex items-center justify-between gap-2">
            <h2 className="text-xl text-secondary font-semibold mb-2 flex items-start gap-2">
              Summer Program{" "}
              {/* <span data-tooltip-id={tooltips.baProgramTitle.id}>
                <MdInfoOutline className="text-primary w-[18px] h-[18px]" />
              </span> */}
            </h2>
            {!isEdit && (
              <button
                className="text-statusColor inline-flex items-center gap-2 text-lg"
                onClick={() => setIsEdit(true)}
              >
                Modify <FiEdit />
              </button>
            )}
            <TooltipComponent
              id={tooltips.baProgramTitle.id}
              content={tooltips.baProgramTitle.content}
              isWhite={true}
            />
          </div>
          <span className="text-base text-secondary font-medium mb-4 block">
            Do you have summer program in your center?
          </span>
          <div
            className={`flex items-center gap-6 mb-6 ${!isEdit ? "opacity-50" : ""
              }`}
          >
            <div className="flex items-center gap-2">
              <input
                type="radio"
                className={`w-5 h-5 border-2 border-gray-400 appearance-none rounded-full flex items-center justify-center checked:border-statusColor focus:outline-none checked:bg-white checked:before:content-[''] checked:before:block checked:before:rounded-full checked:before:bg-statusColor checked:before:w-3 checked:before:h-3  ${!isEdit ? "opacity-50" : "cursor-pointer"
                  }`}
                checked={hasSummerProgram}
                onChange={toggleSummerProgram}
                disabled={!isEdit}
              />
              <label htmlFor="" className="text-sm text-secondaryVariant">
                Yes
              </label>
            </div>
            <div className="flex items-center gap-2">
              <input
                type="radio"
                className={`w-5 h-5 border-2 border-gray-400 appearance-none rounded-full flex items-center justify-center checked:border-statusColor focus:outline-none checked:bg-white checked:before:content-[''] checked:before:block checked:before:rounded-full checked:before:bg-statusColor checked:before:w-3 checked:before:h-3  ${!isEdit ? "opacity-50" : "cursor-pointer"
                  }`}
                checked={!hasSummerProgram}
                onChange={toggleSummerProgram}
                disabled={!isEdit}
              />
              <label htmlFor="" className="text-sm text-secondaryVariant">
                No
              </label>
            </div>
          </div>
          {hasSummerProgram && (
            <>
              <div className="grid grid-cols-7 gap-6 mb-6">
                <div>
                  <label className="text-sm text-secondaryVariant flex items-start gap-2 mb-1">
                    <span>
                      {"Start Date"}
                      <RequiredAsterisk />
                    </span>
                    <span data-tooltip-id={tooltips.summerStartDate.id}>
                      <MdInfoOutline className="text-primary w-[18px] h-[18px]" />
                    </span>
                  </label>
                  <TooltipComponent
                    id={tooltips.summerStartDate.id}
                    content={tooltips.summerStartDate.content}
                    isWhite={true}
                  />
                  <ReactDatePicker
                    key={summerProgramInfo.summerStartDate}
                    placeholder="Select Date"
                    value={revertToDateType(summerProgramInfo.summerStartDate)}
                    onChange={(date) =>
                      handleInputSummerProgram(
                        "summerStartDate",
                        formatDateSlashFullYear(date || "") || ""
                      )
                    }
                    isMonday
                    disabled={!isEdit}
                  />
                </div>
                <div>
                  <InputField
                    key={summerProgramInfo.numberOfWeeks}
                    min={1}
                    label="Weeks"
                    name="classroomName"
                    type="number"
                    placeholder="Summer Weeks"
                    showInfoIcon
                    required
                    value={summerProgramInfo.numberOfWeeks || undefined}
                    onChange={(e) =>
                      handleInputSummerProgram(
                        "numberOfWeeks",
                        Number(e.target.value)
                      )
                    }
                    tooltipId={tooltips.summerWeeks.id}
                    tooltipContent={tooltips.summerWeeks.content}
                    disabled={!isEdit}
                  />
                </div>
                <div>
                  <label className="text-sm text-secondaryVariant flex items-start gap-2 mb-1">
                    <span>
                      {"End Date"}
                      <RequiredAsterisk />
                    </span>
                    <span data-tooltip-id={tooltips.summerEndDate.id}>
                      <MdInfoOutline className="text-primary w-[18px] h-[18px]" />
                    </span>
                    <TooltipComponent
                      id={tooltips.summerEndDate.id}
                      content={tooltips.summerEndDate.content}
                      isWhite={true}
                    />
                  </label>
                  <ReactDatePicker
                    key={summerProgramInfo.summerEndDate}
                    placeholder="End Date"
                    value={revertToDateType(
                      summerProgramInfo.summerEndDate || ""
                    )}
                    onChange={(date) =>
                      handleInputSummerProgram(
                        "summerStartDate",
                        formatDateSlashFullYear(date || "") || ""
                      )
                    }
                    disabled
                  />
                </div>
              </div>
              <h3 className="text-base text-secondary font-medium mb-4 flex items-start gap-2">
                Summer Program Activities{" "}
                <span data-tooltip-id={tooltips.summerActivity.id}>
                  <MdInfoOutline className="text-primary w-[18px] h-[18px]" />
                </span>
              </h3>
              <TooltipComponent
                id={tooltips.summerActivity.id}
                content={tooltips.summerActivity.content}
                isWhite={true}
              />
              <div className="grid grid-cols-7 gap-6 mb-6 items-end">
                {summerProgramInfo?.activities?.length > 0 &&
                  summerProgramInfo?.activities?.map((activity, index) => (
                    <div className="flex items-center gap-2" key={index}>
                      <InputField
                        label={`Activity ${index + 1}`}
                        name="classroomName"
                        type="text"
                        placeholder="After Program"
                        // required
                        value={activity}
                        onChange={(e) => {
                          handleInputSummerProgram(
                            "activities",
                            e.target.value,
                            undefined,
                            index
                          );
                        }}
                        disabled={!isEdit}
                      />
                      {/* <span className="bg-white p-1 rounded-full max-w-8 text-center flex items-center justify-center"> */}
                      <button
                        className="text-dangerAlert pt-6"
                        onClick={() => removeActivity(index)}
                        disabled={!isEdit}
                      >
                        <RiDeleteBin3Line className="w-6 h-6 " />
                      </button>
                      {/* </span> */}
                    </div>
                  ))}
                <div>
                  <button
                    className="text-base text-statusColor flex items-center flex-col"
                    onClick={addActivity}
                    disabled={!isEdit}
                  >
                    <CiCirclePlus className="w-6 h-6" />
                    Add Activity
                  </button>
                </div>
              </div>
              <h3 className="text-base text-secondary font-medium mb-4 flex items-start gap-2">
                How many Summer Program classrooms?{" "}
                {/* <span data-tooltip-id={tooltips.summerClassrooms.id}>
                  <MdInfoOutline className="text-primary w-[18px] h-[18px]" />
                </span> */}
              </h3>
              <TooltipComponent
                id={tooltips.summerClassrooms.id}
                content={tooltips.summerClassrooms.content}
                isWhite={true}
              />
              <div className="grid grid-cols-7 gap-6 mb-6">
                <div>
                  <ReactSelect
                    key={summerProgramInfo.numberOfClassrooms}
                    label="Classrooms"
                    placeholder="Select No."
                    menuPlacement={'auto'}
                    options={numOfClassroomsOptions}
                    value={numOfClassroomsOptions.find(
                      (option) =>
                        option.value === summerProgramInfo.numberOfClassrooms
                    )}
                    onChange={(selectedOption) =>
                      handleInputSummerProgram(
                        "numberOfClassrooms",
                        selectedOption?.value || 0
                      )
                    }
                    required
                    isDisabled={!isEdit}
                  />
                </div>
                {summerProgramInfo?.classrooms?.length > 0 &&
                  summerProgramInfo?.classrooms?.map((classroom, index) => (
                    <div>
                      <InputField
                        label="Name"
                        name="classroomName"
                        type="text"
                        placeholder={`Classroom name ${index + 1}`}
                        required
                        value={classroom.classroomName}
                        onChange={(e) => {
                          handleInputSummerProgram(
                            "classrooms",
                            e.target.value,
                            "classroomName",
                            index
                          );
                        }}
                        disabled={!isEdit}
                      />
                    </div>
                  ))}
              </div>
            </>
          )}
        </div>
        {/*  */}
        <div className="flex items-center justify-between gap-2">
          <h2 className="text-xl text-secondary font-semibold mb-2 flex items-start gap-2">
            Before/After School Program{" "}
            <span data-tooltip-id={tooltips.baProgramTitle.id}>
              <MdInfoOutline className="text-primary w-[18px] h-[18px]" />
            </span>
          </h2>
          <TooltipComponent
            id={tooltips.baProgramTitle.id}
            content={tooltips.baProgramTitle.content}
            isWhite={true}
          />
        </div>
        <span className="text-base text-secondary font-semibold mb-4 block">
          Do you have a Before/After school program in your center?
        </span>
        <div
          className={`flex items-center gap-6 mb-6 ${!isEdit ? "opacity-50" : ""
            }`}
        >
          <div className="flex items-center gap-2">
            <input
              type="radio"
              className={`w-5 h-5 border-2 border-gray-400 appearance-none rounded-full flex items-center justify-center checked:border-primary focus:outline-none checked:bg-white checked:before:content-[''] checked:before:block checked:before:rounded-full checked:before:bg-primary checked:before:w-3 checked:before:h-3 ${isEdit ? "cursor-pointer" : ""
                }`}
              checked={hasBAProgram}
              onChange={toggleBAProgram}
              disabled={!isEdit}
            />
            <label htmlFor="" className="text-sm text-secondaryVariant">
              Yes
            </label>
          </div>
          <div className="flex items-center gap-2">
            <input
              type="radio"
              className={`w-5 h-5 border-2 border-gray-400 appearance-none rounded-full flex items-center justify-center checked:border-primary focus:outline-none checked:bg-white checked:before:content-[''] checked:before:block checked:before:rounded-full checked:before:bg-primary checked:before:w-3 checked:before:h-3 ${isEdit ? "cursor-pointer" : ""
                }`}
              checked={!hasBAProgram}
              onChange={toggleBAProgram}
              disabled={!isEdit}
            />
            <label htmlFor="" className="text-sm text-secondaryVariant">
              No
            </label>
          </div>
        </div>
        {hasBAProgram && (
          <>
            {!hasSummerProgram && (
              <div className="mb-6">
                <h3 className="text-base text-secondary font-medium mb-4">
                  Please enter County-Defined Summer vacation start and end
                  dates
                </h3>

                <div className="grid grid-cols-6 gap-6">
                  <div className=" w-full">
                    <label htmlFor="" className="text-sm text-secondaryVariant">
                      Start Date
                      <RequiredAsterisk />
                    </label>

                    <div className="w-full relative">
                      <ReactDatePicker
                        key={baProgramInfo.countySummerStartDate}
                        placeholder="Select Date"
                        value={revertToDateType(
                          baProgramInfo.countySummerStartDate
                            ? baProgramInfo.countySummerStartDate
                            : ""
                        )}
                        onChange={(date) =>
                          handleInputBaProgram(
                            "countySummerStartDate",
                            formatDateSlashFullYear(date || "") || ""
                          )
                        }
                        disabled={!isEdit}
                      />

                      <PiCalendarPlus className="w-5 h-5 absolute right-3 top-4 pointer-events-none" />
                    </div>
                  </div>

                  <div className=" w-full">
                    <label htmlFor="" className="text-sm text-secondaryVariant">
                      End Date
                      <RequiredAsterisk />
                    </label>

                    <div className="w-full relative">
                      <ReactDatePicker
                        key={baProgramInfo.countySummerEndDate}
                        placeholder="Select Date"
                        value={revertToDateType(
                          baProgramInfo.countySummerEndDate
                            ? baProgramInfo.countySummerEndDate
                            : ""
                        )}
                        onChange={(date) =>
                          handleInputBaProgram(
                            "countySummerEndDate",
                            formatDateSlashFullYear(date || "") || ""
                          )
                        }
                        disabled={!isEdit}
                      />

                      <PiCalendarPlus className="w-5 h-5 absolute right-3 top-4 pointer-events-none" />
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="flex items-center gap-2 mb-4">
              <h4 className="font-semibold text-secondary">
                How many classrooms do you have in B/A Program?
              </h4>
              {/* <span data-tooltip-id={tooltips.baProgramTitle.id}>
                <MdInfoOutline className="text-primary w-[18px] h-[18px]" />
              </span> */}
            </div>
            <div className="grid grid-cols-6 gap-6">
              <div>
                <ReactSelect
                  key={baProgramInfo.numberOfClassrooms}
                  label="No. of Classrooms?"
                  placeholder="Select No."
                  menuPlacement={'auto'}
                  options={numOfClassroomsOptions}
                  value={numOfClassroomsOptions.find(
                    (option) =>
                      option.value === baProgramInfo.numberOfClassrooms
                  )}
                  onChange={(selectedOption) =>
                    handleInputBaProgram(
                      "numberOfClassrooms",
                      selectedOption?.value || 0
                    )
                  }
                  required
                  isDisabled={!isEdit}
                />
              </div>
              {baProgramInfo?.classrooms?.length > 0 &&
                baProgramInfo?.classrooms?.map((classroom, index) => (
                  <div>
                    <InputField
                      label="Name"
                      name="classroomName"
                      type="text"
                      placeholder={`Classroom name ${index + 1}`}
                      capitalizeTextClass={true}
                      required
                      value={classroom.classroomName}
                      onChange={(e) => {
                        handleInputBaProgram(
                          "classrooms",
                          e.target.value,
                          undefined,
                          index
                        );
                      }}
                      disabled={!isEdit}
                    />
                  </div>
                ))}
            </div>

            <div className="">
              <div className="grid grid-cols-2 gap-12 mt-6">
                {baProgramInfo?.partTimeSlots?.map((slot, index) => (
                  <div key={index}>
                    <h2 className="font-semibold text-secondary mb-4 ">
                      {handleBaProgramTitle(index)}
                    </h2>
                    <div className="grid grid-cols-3 gap-6">
                      <div>
                        <InputField
                          label="Name"
                          name="classroomName"
                          type="text"
                          placeholder="Before Program"
                          value={slot.name}
                          onChange={(e) =>
                            handleInputBaProgram(
                              "partTimeSlots",
                              e.target.value,
                              "name",
                              index
                            )
                          }
                          required
                          disabled
                        />
                      </div>
                      {index !== 2 ? (
                        <>
                          <div>
                            <TimeDropdown
                              times={timeOptions}
                              label="Start Time"
                              required
                              placeholder="Set time"
                              value={slot.startTime}
                              onSelectTime={(selectedTime) =>
                                handleInputBaProgram(
                                  "partTimeSlots",
                                  selectedTime,
                                  "startTime",
                                  index
                                )
                              }
                              disabled={!isEdit}
                            />
                          </div>
                          <div>
                            <TimeDropdown
                              times={timeOptions}
                              label="End Time"
                              required
                              placeholder="Set time"
                              value={slot.endTime}
                              onSelectTime={(selectedTime) =>
                                handleInputBaProgram(
                                  "partTimeSlots",
                                  selectedTime,
                                  "endTime",
                                  index
                                )
                              }
                              disabled={!isEdit}
                            />
                          </div>
                        </>
                      ) : (
                        // <>
                        // <div></div>
                        <div
                          className={`text-dangerAlert text-base font-medium pt-12 col-span-2 truncate  ${!isEdit ? "opacity-50" : ""
                            }`}
                        >
                          Sessions will be a combination of the above timings
                        </div>
                        // </>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="my-6">
              <div className="flex items-center gap-2 mb-4">
                <h4 className="font-semibold text-secondary">
                  Elementary School
                </h4>
                <span data-tooltip-id={tooltips.elementarySchool.id}>
                  <MdInfoOutline className="text-primary w-[18px] h-[18px]" />
                </span>
                <TooltipComponent
                  id={tooltips.elementarySchool.id}
                  content={tooltips.elementarySchool.content}
                  isWhite={true}
                />
              </div>
              <h3 className="text-sm text-secondary mb-2">
                Elementary schools served
              </h3>
            </div>
            <div className="grid grid-cols-7 gap-6 mb-6 items-end">
              {baProgramInfo?.elementarySchools?.length > 0 &&
                baProgramInfo?.elementarySchools?.map((school, index) => (
                  <div className="flex items-center gap-2" key={index}>
                    <InputField
                      label={`School ${index + 1}`}
                      name="schoolName"
                      type="text"
                      placeholder="School Name"
                      // required
                      value={school.schoolName}
                      onChange={(e) => {
                        handleInputBaProgram(
                          "elementarySchools",
                          e.target.value,
                          "schoolName",
                          index
                        );
                      }}
                      disabled={!isEdit}
                    />
                    <span
                      className={`bg-white p-1 rounded-full max-w-8 text-center flex items-center justify-center pt-8 ${!isEdit ? "opacity-50" : ""
                        }`}
                    >
                      <button
                        className="text-dangerAlert"
                        onClick={() => removeElementarySchool(index)}
                        disabled={!isEdit}
                      >
                        <RiDeleteBin3Line className="w-6 h-6 " />
                      </button>
                    </span>
                  </div>
                ))}
              <div className="pb-4">
                <button
                  className={`text-base text-statusColor flex items-center ${!isEdit ? "opacity-50" : ""
                    }`}
                  onClick={addElementarySchool}
                  disabled={!isEdit}
                >
                  <CiCirclePlus className="w-6 h-6" />
                  Add
                </button>
              </div>
            </div>
          </>
        )}

        {specialClassroomsErrors?.length > 0 && isEdit && (
          <div className="mb-3">
            {specialClassroomsErrors?.map((error, index) => (
              <p key={index} className="text-dangerAlert">
                {error}
              </p>
            ))}
          </div>
        )}
        {isEdit && (
          <div className="flex items-center gap-4 ">
            <button
              className={`btnPrimary max-w-[125px] ${isSaveDisabled ? "opacity-50" : ""
                }`}
              onClick={handleSaveClick}
              disabled={isSaveDisabled}
            >
              Save
            </button>
            <button
              className="btnSimple max-w-[125px]"
              onClick={handleCancelClick}
            >
              Cancel
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default BeforeAfterSchoolProgram;
