import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SingleValue } from "react-select";
import Breadcrumb, {
  BreadcrumbItem,
} from "../../../hr-setup_modules/components/Breadcrumb";
import DashboardWrapper from "../../../shared/components/layout/DashboardWrapper";
import { formatDateSlashFullYear } from "../../../shared/utils/formatDate";
import { parseTime } from "../../../shared/utils/parseTime";
import { showErrorToast, showSuccessToast } from "../../../shared/utils/toast";
import BeforeAfterSchoolProgram from "../../components/Essential/BeforeAfterSchoolProgram";
import EssentialInformation from "../../components/Essential/EssentialInformation";
import ReviewEssentialClassroomsModal from "../../components/Essential/ReviewEssentialClassroomsModal";
import EssentialTransition from "../../components/essentialTransition/EssentialTransition";
import ConfirmationModal from "../../components/modals/BaProgramConfirmationModal";
import SelectClassRoomModal from "../../components/modals/SelectClassRoomModal";
import SureToDeactivateModal from "../../components/modals/SureToDeactiveModal";
import NonAgeEssential from "../../components/nonAgeEssential/NonAgeEssential";
import {
  initBAProgramInfo,
  initSummerProgram,
} from "../../constants/initialState";
import { toastMessage } from "../../constants/toastMessages";
import { SessionType } from "../../enums/Foray.enums";
import { useCenterSetup } from "../../hooks/useCenterSetup";
import { usePostEssentialClassrooms } from "../../hooks/useCreateEssentialClassrooms";
import { useUpdateCenterSetup } from "../../hooks/useUpdateCenterSetup";
import { useUpdatePartTimeSlots } from "../../hooks/useUpdatePartTimeSlots";
import { forayRoutes } from "../../routes/routes";
import { classroomSvc } from "../../services/classroom.service";
import { studentSvc } from "../../services/student.service";
import {
  ICenterSetupPayload,
  IElementarySchool,
  IOptionS,
  SummerProgram,
} from "../../types/ICenterSetup.interface";
import {
  IClassroomSetup,
  IEssentialClassroomSetup,
  // IPartTimeSessions,
  IPartTimeSessionsClassDetailed,
  IPartTimeSlots,
  IProgramClassrooms,
  NewClassroom,
} from "../../types/IClassroomSetup.interface";
import { calculateYearsMonthsDays } from "../../utils/functions/calculateYMD";
import {
  convertToDays,
  validateClassrooms,
} from "../../utils/functions/validateClassrooms";
import PartTimeSession from "./PartTimeSession";
import SetupInstruction from "./SetupInstruction";

const initialMinMaxAge = {
  years: 0,
  months: 0,
  days: 0,
  ageInDays: 0,
  countAllowedOutsideRange: 0,
};

const initialNewClassroom: any = {
  classroomName: "",
  ageRange: {
    minimumAge: { ...initialMinMaxAge },
    maximumAge: { ...initialMinMaxAge },
  },
  partTimeSlots: [],
};

const EssentialClassroomSetup = () => {
  const [seqValidationErrors, setSeqValidationErrors] = useState<string[]>([]);
  const [nonSeqValidationErrors, setNonSeqValidationErrors] = useState<
    string[]
  >([]);
  const [partTimeSessions, setPartTimeSessions] = useState<IPartTimeSlots[]>(
    []
  );
  const [classroomData, setClassroomData] = useState<IClassroomSetup[]>([]);
  const [isOpenReviewModal, setIsOpenReviewModal] = useState(false);
  const [savingClassrooms, setSavingClassrooms] = useState("");
  const [havePartTimeSessions, setHavePartTimeSessions] = useState(false);
  const [editSequentialClasses, setEditSequentialClasses] = useState(false);
  const [editNonSequentialClasses, setEditNonSequentialClasses] =
    useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [classrooms, setClassrooms] = useState<NewClassroom[]>([
    initialNewClassroom,
  ]);
  const [nonAgeSequentialClassrooms, setNonAgeSequentialClassrooms] = useState<
    NewClassroom[]
  >([]);
  const [baProgramInfo, setBaProgramInfo] = useState(initBAProgramInfo);
  const [summerProgramInfo, setSummerProgramInfo] = useState(initSummerProgram);
  const [classroomOptions, setClassroomOptions] = useState<IOptionS[]>([]);
  const [hasBAProgram, setHasBAProgram] = useState(false);
  const [hasSummerProgram, setHasSummerProgram] = useState(false);
  const [autoTransition, setAutoTransition] = useState(true);
  const [
    removeClassroomsSelectionModalOpen,
    setRemoveClassroomsSelectionModalOpen,
  ] = useState(false);
  const [
    allowedNumberOfClassroomsToRemove,
    setAllowedNumberOfClassroomsToRemove,
  ] = useState(0);
  const [classroomsToDelete, setClassroomsToDelete] = useState("");
  const [transitionSlotModalOpen, setTransitionSlotModalOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [specialClassroomsErrors, setSpecialClassroomsErrors] = useState<
    string[]
  >([]);
  const prevRoute = new URLSearchParams(window.location.search).get("pr");
  const [BAClassrooms, setBAClassrooms] = useState([]);

  const navigate = useNavigate();

  const breadcrumbItems = [
    { label: "Foray", link: forayRoutes.foray_screen },
    prevRoute === forayRoutes.center_view
      ? { label: "Center View", link: forayRoutes.center_view }
      : {
          label: "Classroom-Essentials",
          link: forayRoutes.essential_classroom_list,
        },
    { label: "Classrooms-Essentials Setup", isCurrent: true },
  ] as BreadcrumbItem[];

  // below is the code for ba and summer program
  const toggleBAProgram = () => {
    setHasBAProgram((prev) => !prev);
  };
  

  const toggleSummerProgram = () => {
    setHasSummerProgram((prev) => !prev);
  };

  const toggleAutoTransition = () => {
    setAutoTransition((prev) => !prev);
  };

  const validateSpecialClassroomNames = (): boolean => {
    setSpecialClassroomsErrors([]);

    const summerClassroomNames = summerProgramInfo.classrooms?.map(
      (classroom) => classroom.classroomName?.trim().toLowerCase()
    );
    const summerActivityNames = summerProgramInfo.activities?.map((activity) =>
      activity?.trim().toLowerCase()
    );
    const baClassroomNames = baProgramInfo.classrooms?.map((classroom) =>
      classroom.classroomName?.trim().toLowerCase()
    );
    const elementarySchools = baProgramInfo.elementarySchools?.map((school) =>
      school.schoolName?.trim().toLowerCase()
    );

    const errors: string[] = [];

    if (!hasSummerProgram && hasBAProgram) {
      if (!baProgramInfo.countySummerStartDate) {
        errors.push("County Summer Start Date is required.");
      }
      if (!baProgramInfo.countySummerEndDate) {
        errors.push("County Summer End Date is required.");
      }
    }

    const emptySummerClassrooms = summerClassroomNames?.some((name) => !name);
    if (emptySummerClassrooms) {
      errors.push("Some classroom names are missing in the Summer Program.");
    }

    const emptySummerActivities = summerActivityNames?.some((name) => !name);
    if (emptySummerActivities) {
      errors.push("Some activity names are missing in the summer activities.");
    }

    const emptyBAClassrooms = baClassroomNames?.some((name) => !name);
    if (emptyBAClassrooms) {
      errors.push("Some classroom names are missing in the BA Program.");
    }

    const emptyElementarySchools = elementarySchools?.some((name) => !name);
    if (emptyElementarySchools) {
      errors.push("Some school names are missing in elementary schools.");
    }

    // ✅ Validate each session in BA Program
    let beforeSchool: IPartTimeSlots | undefined;
    let afterSchool: IPartTimeSlots | undefined;
    if (hasBAProgram) {
      baProgramInfo.partTimeSlots?.forEach((session, index) => {
        if (!session.name || !session.startTime || !session.endTime) {
          errors.push(`BA Program session ${index + 1} has missing fields.`);
        }

        if (
          session.startTime &&
          session.endTime &&
          parseTime(session.startTime) >= parseTime(session.endTime)
        ) {
          errors.push(
            `Session "${session.name}" must have an end time greater than the start time.`
          );
        }

        // Store "Before School" and "After School" sessions
        if (session.name.toLowerCase() === "before school") {
          beforeSchool = session;
        } else if (session.name.toLowerCase() === "after school") {
          afterSchool = session;
        }
      });

      // ✅ Check for overlapping between Before School & After School
      if (beforeSchool && afterSchool) {
        const beforeEndTime = parseTime(beforeSchool.endTime);
        const afterStartTime = parseTime(afterSchool.startTime);

        if (beforeEndTime > afterStartTime) {
          errors.push(
            `"Before School" session cannot overlap with "After School" session timings.`
          );
        }
      }
    }

    // ✅ Check for duplicate classroom names
    if (!emptySummerClassrooms && !emptyBAClassrooms) {
      const hasDuplicates = (names: string[]): boolean => {
        const uniqueNames = new Set(names);
        return uniqueNames.size !== names?.length;
      };

      if (hasDuplicates(summerClassroomNames)) {
        errors.push("Duplicate classroom names found in Summer Program.");
      }

      if (hasDuplicates(baClassroomNames)) {
        errors.push("Duplicate classroom names found in BA Program.");
      }

      const duplicatesBetweenPrograms = summerClassroomNames?.some((name) =>
        baClassroomNames.includes(name)
      );
      if (duplicatesBetweenPrograms) {
        errors.push(
          "Classroom names in Summer and BA Programs must be unique across both programs."
        );
      }
    }

    setSpecialClassroomsErrors(errors);
    return errors.length === 0;
  };

  const handleInputBaProgram = (
    name: keyof typeof initBAProgramInfo,
    value: string | number,
    subKey?:
      | keyof IPartTimeSessionsClassDetailed
      | keyof IProgramClassrooms
      | keyof IElementarySchool,
    index?: number
  ) => {
    console.log("value", value, "nonSequentialClassroom", BAClassrooms.length);
    console.log("name", name, "value", value);
    if (
      name === "numberOfClassrooms" &&
      typeof value === "number" &&
      (value as number) < baProgramInfo.numberOfClassrooms &&
      BAClassrooms.length !== value
    ) {
      handleOpenRemoveClassroomSelectionModal();
      setAllowedNumberOfClassroomsToRemove(
        baProgramInfo.numberOfClassrooms - value
      );
      setClassroomsToDelete("ba");
      return;
    }
    setBaProgramInfo((prev) => {
      if (name === "classrooms" && typeof index === "number") {
        // Handle updates for the classrooms array
        const updatedClassrooms = [...prev.classrooms];
        updatedClassrooms[index].classroomName = value as string;
        return {
          ...prev,
          classrooms: updatedClassrooms,
        };
      }

      if (name === "partTimeSlots" && subKey && typeof index === "number") {
        // Handle updates for the partTimeSlots array
        const updatedPartTimeSlots = prev.partTimeSlots?.map((item, i) => {
          if (i === index) {
            return { ...item, [subKey]: value as string };
          }
          if (i === 2) {
            // Dynamically update BEFORE_AFTER_SCHOOL based on BEFORE_SCHOOL and AFTER_SCHOOL
            return {
              ...item,
              startTime:
                index === 0 && subKey === "startTime"
                  ? (value as string)
                  : prev.partTimeSlots[0]?.startTime,
              endTime:
                index === 1 && subKey === "endTime"
                  ? (value as string)
                  : prev.partTimeSlots[1]?.endTime,
            };
          }
          return item;
        });
        return {
          ...prev,
          partTimeSlots: updatedPartTimeSlots,
        };
      }
      if (name === "elementarySchools" && subKey && typeof index === "number") {
        const updatedElementarySchools = [...prev.elementarySchools];
        updatedElementarySchools[index] = {
          ...updatedElementarySchools[index],
          [subKey]: value as string,
        };
        return {
          ...prev,
          elementarySchools: updatedElementarySchools,
        };
      }

      // if (name === "numberOfClassrooms") {
      //   // Handle updates for numberOfClassrooms
      //   const updatedClassrooms = Array.from(
      //     { length: value as number },
      //     (_, i) => prev.classrooms[i] || `Classroom ${i + 1}`
      //   );
      //   return {
      //     ...prev,
      //     numberOfClassrooms: value as number,
      //     classrooms: updatedClassrooms,
      //   };
      // }

      // Default case for top-level fields
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleInputSummerProgram = (
    name: keyof typeof initSummerProgram,
    value: string | number,
    subKey?: keyof IProgramClassrooms,
    index?: number
  ) => {
    if (
      name === "numberOfClassrooms" &&
      typeof value === "number" &&
      (value as number) < summerProgramInfo.numberOfClassrooms
    ) {
      handleOpenRemoveClassroomSelectionModal();
      setAllowedNumberOfClassroomsToRemove(
        summerProgramInfo.numberOfClassrooms - value
      );
      setClassroomsToDelete("summer");
      return;
    }
    setSummerProgramInfo((prev) => {
      const fieldValue = prev[name];
      if (name === "numberOfWeeks") {
        if (value === 0) {
          value = 1;
        }
      }

      if (typeof index === "number" && Array.isArray(fieldValue)) {
        // Handle updates to array fields like 'activities' or 'classrooms'
        const updatedArray = fieldValue?.map((item, i) => {
          if (i === index) {
            if (name === "classrooms" && subKey) {
              // Handle nested updates for classrooms
              return { ...(item as IProgramClassrooms), [subKey]: value };
            }
            return value as string; // For activities or other string arrays
          }
          return item;
        });
        return {
          ...prev,
          [name]: updatedArray,
        };
      }

      if (!Array.isArray(fieldValue)) {
        // Handle top-level fields
        return {
          ...prev,
          [name]: value,
        };
      }

      // Default return for unsupported cases
      return prev;
    });
  };

  const summerProgramEndDate = () => {
    if (summerProgramInfo.summerStartDate && summerProgramInfo.numberOfWeeks) {
      const startDate = new Date(summerProgramInfo.summerStartDate);
      const daysToAdd = (summerProgramInfo.numberOfWeeks - 1) * 7;

      // Calculate the tentative end date
      const tentativeEndDate = new Date(startDate);
      tentativeEndDate.setDate(tentativeEndDate.getDate() + daysToAdd);
      // Adjust to the nearest Friday
      const dayOfWeek = tentativeEndDate.getDay();
      const adjustment = dayOfWeek < 5 ? 5 - dayOfWeek : dayOfWeek - 5;
      const endDate = new Date(tentativeEndDate);
      endDate.setDate(tentativeEndDate.getDate() + adjustment);

      return endDate;
    }
    return null;
  };

  useEffect(() => {
    if (!summerProgramInfo.summerStartDate || !summerProgramInfo.numberOfWeeks)
      return;
    const formattedDate = moment(summerProgramEndDate())?.format("MM/DD/YYYY");
    setSummerProgramInfo((prev) => ({
      ...prev,
      summerEndDate: formattedDate,
    }));
  }, [summerProgramInfo.numberOfWeeks, summerProgramInfo.summerStartDate]);

  const addElementarySchool = () => {
    setBaProgramInfo((prev) => ({
      ...prev,
      elementarySchools: [
        ...(prev?.elementarySchools || []), // Fallback to an empty array if undefined
        { schoolName: "", uuid: "" },
      ],
    }));
  };

  const removeElementarySchool = (index: number) => {
    setBaProgramInfo((prev) => ({
      ...prev,
      elementarySchools: prev?.elementarySchools?.filter((_, i) => i !== index),
    }));
  };

  const addActivity = () => {
    setSummerProgramInfo((prev) => ({
      ...prev,
      activities: [...prev.activities, ""],
    }));
  };

  const removeActivity = (index: number) => {
    setSummerProgramInfo((prev) => ({
      ...prev,
      activities: prev.activities.filter((_, i) => i !== index),
    }));
  };
  const handleSavePrograms = async () => {
    console.log("baProgramInfo", baProgramInfo);
    // Create BA program classrooms
    const baClassrooms = Array.from(
      { length: baProgramInfo?.numberOfClassrooms || 0 },
      (_, index) => {
        if (baProgramInfo.classrooms[index]?._id) return;
        return {
          classroomName:
            baProgramInfo?.classrooms[index]?.classroomName ||
            `BA ${index + 1}`,
          ageRange: { ...initialNewClassroom.ageRange }, // Copy ageRange structure
          partTimeSlots: baProgramInfo?.partTimeSlots || [],
          settingType: SessionType.FullAndPartTime, // Add required settingType
          BAProgram: true, // Add required BAProgram flag
          summerProgram: false,
        };
      }
    ).filter((classroom) => !!classroom);

    //editted
    const editedBAClassrooms: NewClassroom[] = baProgramInfo?.classrooms
      .filter((classroom) => classroom._id)
      .map((classroom) => ({
        _id: classroom._id,
        classroomName: classroom.classroomName,
        ageRange: { ...initialNewClassroom.ageRange },
        partTimeSlots: baProgramInfo?.partTimeSlots || [],
        settingType: SessionType.FullAndPartTime,
        BAProgram: true,
        summerProgram: false,
      }));

    // Create Summer program classrooms
    const summerClassrooms = Array.from(
      { length: summerProgramInfo?.numberOfClassrooms || 0 },
      (_, index) => {
        if (summerProgramInfo.classrooms[index]?._id) return;
        return {
          classroomName:
            summerProgramInfo?.classrooms[index]?.classroomName ||
            `Summer ${index + 1}`, // summerProgramInfo?.classrooms[index],,
          ageRange: { ...initialNewClassroom.ageRange }, // Copy ageRange structure
          partTimeSlots: [],
          settingType: SessionType.FullTimeOnly, // Add required settingType
          BAProgram: false, // Add required BAProgram flag
          summerProgram: true,
        };
      }
    ).filter((classroom) => !!classroom);
    const centerSetupUpdated = await handleUpdateCenterSetup(
      hasBAProgram ? baProgramInfo.elementarySchools : [],
      {
        summerStartDate:
          formatDateSlashFullYear(summerProgramInfo.summerStartDate) || "",
        summerEndDate:
          formatDateSlashFullYear(summerProgramInfo.summerEndDate as string) ||
          "",

        numberOfWeeks: summerProgramInfo.numberOfWeeks as number,
        activities: summerProgramInfo.activities,
      },
      hasBAProgram && !hasSummerProgram
        ? baProgramInfo.countySummerStartDate
        : null,
      hasBAProgram && !hasSummerProgram
        ? baProgramInfo.countySummerEndDate
        : null
    );

    if (!centerSetupUpdated) return;
    // Combine and set non-sequential classrooms

    setNonAgeSequentialClassrooms([
      ...(baClassrooms as NewClassroom[]),
      ...(summerClassrooms as NewClassroom[]),
    ]);

    if (baProgramInfo?.numberOfClassrooms === 0) {
      await Promise.all(
        BAClassrooms.map((classroom: any) =>
          classroomSvc.archiveSpecialClassroom(classroom._id, false, {
            numberOfBAClassrooms: 0,
            numberOfSummerClassrooms: summerProgramInfo.numberOfClassrooms,
          })
        )
      );
    } else if (summerProgramInfo?.numberOfClassrooms === 0) {
      await Promise.all(
        summerProgramInfo.classrooms.map((classroom: any) =>
          classroomSvc.archiveSpecialClassroom(classroom._id, false, {
            numberOfBAClassrooms: 0,
            numberOfSummerClassrooms: baProgramInfo.numberOfClassrooms,
          })
        )
      );
    } else {
      await handleCreateClassroomsPrograms(
        baClassrooms as NewClassroom[],
        summerClassrooms as NewClassroom[],
        editedBAClassrooms
      );
    }

    await fetchClassrooms();
    setEditNonSequentialClasses(true);
    {
      (summerClassrooms?.length > 0 || baClassrooms?.length > 0) &&
        showSuccessToast(
          `${
            hasBAProgram && baClassrooms?.length > 0
              ? baClassrooms?.length + " BA Program classroom(s) created"
              : ""
          } ${
            hasSummerProgram && summerClassrooms?.length > 0
              ? summerClassrooms?.length +
                " Summer Program classroom(s) created"
              : ""
          }`
        );
    }
  };

  const handleCancelProgram = () => {
    setEditNonSequentialClasses(false);
    fetchClassrooms();
    getCenterSetup();
  };

  const handleSetNumberOfClassrooms = (classrooms: IClassroomSetup[]) => {
    const baClassrooms = classrooms.filter((classroom) => classroom.BAProgram);
    const summerClassrooms = classrooms.filter(
      (classroom) => classroom.summerProgram
    );
    if (baClassrooms.length > 0) {
      setBaProgramInfo((prev) => ({
        ...prev,
        numberOfClassrooms: baClassrooms.length,
        classrooms: baClassrooms?.map((classroom) => ({
          classroomName: classroom.classroomName,
          _id: classroom._id as string,
        })),
        partTimeSlots: baClassrooms[0].partTimeSlots,
      }));
      setHasBAProgram(true);
    }
    // else {
    //   setHasBAProgram(false);
    // }

    if (summerClassrooms?.length > 0) {
      setSummerProgramInfo((prev) => ({
        ...prev,
        numberOfClassrooms: summerClassrooms.length,
        classrooms: summerClassrooms?.map((classroom) => ({
          classroomName: classroom.classroomName,
          _id: classroom._id as string,
        })),
      }));
    }
    // else {
    //   setHasSummerProgram(false);
    // }
  };

  const handleCreateClassroomsPrograms = async (
    baClassrooms: NewClassroom[] | undefined,
    summerClassrooms: NewClassroom[] | undefined,
    editedBAClassrooms: NewClassroom[] | undefined
  ) => {
    console.log("baClassrooms", baClassrooms);
    console.log("summerClassrooms", summerClassrooms);
    const reqBody: any = {
      // summerClassrooms: summerClassrooms,
      // BAClassrooms: baClassrooms,
      numberOfSummerClassrooms: summerProgramInfo?.numberOfClassrooms,
      numberOfBAClassrooms: baProgramInfo?.numberOfClassrooms,
    };
    if (summerClassrooms) {
      reqBody.summerClassrooms = summerClassrooms;
    }
    if (baClassrooms) {
      reqBody.BAClassrooms = baClassrooms;
    }

    if (editedBAClassrooms && editedBAClassrooms.length > 0) {
      reqBody.editedBAClassrooms = editedBAClassrooms;
    }
    const res = await classroomSvc.createClassroomsPrograms(reqBody);
  };

  const handleArchiveSpecialClassroom = async (
    classrooms: IProgramClassrooms[],
    checkArray: boolean[]
  ) => {
    // Get the IDs of the selected classrooms
    // classroomsToDelete

    const selectedClassrooms = classrooms
      ?.filter((_, index) => checkArray[index])
      ?.map((classroom) => classroom._id);

    if (selectedClassrooms.length === 0) {
      console.log("No classrooms selected for archiving.");
      return;
    }
    // const
    const reqBody = {
      numberOfSummerClassrooms:
        classroomsToDelete === "ba"
          ? summerProgramInfo?.numberOfClassrooms
          : summerProgramInfo?.numberOfClassrooms -
            allowedNumberOfClassroomsToRemove, //selectedClassrooms?.length,
      numberOfBAClassrooms:
        classroomsToDelete === "summer"
          ? baProgramInfo?.numberOfClassrooms
          : baProgramInfo?.numberOfClassrooms -
            allowedNumberOfClassroomsToRemove, //selectedClassrooms?.length
    };
    // Loop through selected classrooms and call the API for each
    for (const classroomId of selectedClassrooms) {
      if (!classroomId) break;
      try {
        const res = await classroomSvc.archiveSpecialClassroom(
          classroomId,
          false,
          reqBody
        );
        if (res?.ok) {
          console.log(
            `Classroom with ID ${classroomId} archived successfully.`
          );
        } else {
          const classroomName = classrooms.find(
            (classroom) => classroomId === classroom._id
          )?.classroomName;
          showErrorToast(
            `Change "${classroomName}" classroom for transition, then try again.`
          );
          console.error(
            `Failed to archive classroom with ID ${classroomId}:`,
            res
          );
          break;
        }
      } catch (error) {
        console.error(
          `Error archiving classroom with ID ${classroomId}:`,
          error
        );
      }
    }

    // Optionally reset the checkboxes or refresh data
    // handleMapCheck();
    getCenterSetup();
    fetchClassrooms();
    setClassroomsToDelete("");
    handleCloseRemoveClassroomSelectionModal();
  };

  const handleOpenRemoveClassroomSelectionModal = () =>
    setRemoveClassroomsSelectionModalOpen(true);
  const handleCloseRemoveClassroomSelectionModal = () =>
    setRemoveClassroomsSelectionModalOpen(false);

  useEffect(() => {
    if (hasBAProgram && baProgramInfo.numberOfClassrooms > 0) {
      setBaProgramInfo((prev) => {
        const currentClassrooms = prev.classrooms || [];
        const currentCount = currentClassrooms.length;
        const requiredCount = baProgramInfo.numberOfClassrooms;

        if (requiredCount > currentCount) {
          // Add additional classrooms as empty strings
          const additionalClassrooms = Array.from(
            { length: requiredCount - currentCount },
            () => ({ classroomName: "", _id: "" })
          );
          return {
            ...prev,
            classrooms: [...currentClassrooms, ...additionalClassrooms],
          };
        }

        if (requiredCount < currentCount) {
          // Trim the classrooms array
          return {
            ...prev,
            classrooms: currentClassrooms.slice(0, requiredCount),
          };
        }

        // If the count is the same, return the existing state
        return prev;
      });
    }
    if (!hasBAProgram) {
      setBaProgramInfo(initBAProgramInfo);
    }
  }, [baProgramInfo.numberOfClassrooms, hasBAProgram]);

  useEffect(() => {
    if (hasSummerProgram && summerProgramInfo.numberOfClassrooms > 0) {
      setSummerProgramInfo((prev) => {
        const currentClassrooms = prev.classrooms || [];
        const currentCount = currentClassrooms?.length;
        const requiredCount = summerProgramInfo.numberOfClassrooms;

        if (requiredCount > currentCount) {
          // Add additional classrooms as empty strings
          const additionalClassrooms = Array.from(
            { length: requiredCount - currentCount },
            () => ({ classroomName: "", _id: "" })
          );
          return {
            ...prev,
            classrooms: [...currentClassrooms, ...additionalClassrooms],
          };
        }

        if (requiredCount < currentCount) {
          // Trim the classrooms array
          return {
            ...prev,
            classrooms: currentClassrooms.slice(0, requiredCount),
          };
        }

        // If the count is the same, return the existing state
        return prev;
      });
    }
  }, [summerProgramInfo.numberOfClassrooms, hasSummerProgram]);
  ///Review to move the useEffects below into above useEffects
  useEffect(() => {
    if (hasSummerProgram) return;
    setSummerProgramInfo(initSummerProgram);
  }, [hasSummerProgram]);

  useEffect(() => {
    validateSpecialClassroomNames();
  }, [summerProgramInfo, baProgramInfo]);

  // till here is the code for ba and summer program
  const { data: center } = useCenterSetup();
  const getCenterSetup = async () => {
    // const res = await foraySvc.fetchCenterSetup();

    const centerSetup = center?.existingCenterSetup;
    if (centerSetup?.summerProgram?.summerStartDate) {
      setHasSummerProgram(true);
    }

    if (centerSetup?.partTimeSlots?.length > 0) {
      setPartTimeSessions(centerSetup?.partTimeSlots);
      setHavePartTimeSessions(true);
    }

    // if (centerSetup?.elementarySchools?.length > 0) {
    //   setHasBAProgram(true)
    // }
    setBaProgramInfo((prev) => ({
      ...prev,
      elementarySchools: centerSetup?.elementarySchools,
    }));

    setSummerProgramInfo((prev) => ({
      ...prev,
      summerStartDate: centerSetup?.summerProgram?.summerStartDate,
      summerEndDate: centerSetup?.summerProgram?.summerEndDate,
      numberOfWeeks: centerSetup?.summerProgram?.numberOfWeeks,
      activities: centerSetup?.summerProgram?.activities || [],
      // numberOfClassrooms: centerSetup?.numberOfClassrooms || null,
      // classrooms: centerSetup?.summerProgram?.classrooms || [],
    }));
  };

  const { updateCenterSetup } = useUpdateCenterSetup();
  const handleUpdateCenterSetup = async (
    elementarySchools: IElementarySchool[],
    summerProgram: SummerProgram,
    countySummerStartDate?: string | null,
    countySummerEndDate?: string | null
  ) => {
    const reqBody: ICenterSetupPayload = {
      elementarySchools,
      countySummerStartDate,
      countySummerEndDate,
    };
    if (hasSummerProgram) {
      reqBody.summerProgram = summerProgram;
    }
    const res = await updateCenterSetup(reqBody, false);
    if (res?.ok) return true;
    else return false;
  };

  const fetchClassrooms = async () => {
    const resp = await classroomSvc.fetchClassrooms();
    setClassroomData(resp?.data.existingClassroomSetups);
    const formattedClassrooms =
      resp?.data?.existingClassroomSetups?.map(
        (classroom: IClassroomSetup) => ({
          value: classroom._id,
          label: classroom.classroomName,
        })
      ) || [];

    // const partTimeSlotsClassroom = resp?.data?.existingClassroomSetups?.find(
    //   (classroom: IClassroomSetup) =>
    //     classroom?.partTimeSlots?.length > 0 && !classroom?.BAProgram
    // );
    // if (partTimeSlotsClassroom) {
    //   setPartTimeSessions(() => {
    //     const updatedPartTimeSessions =
    //       partTimeSlotsClassroom?.partTimeSlots?.map(
    //         (session: IPartTimeSlots) => ({
    //           name: session.name,
    //           startTime: session.startTime,
    //           endTime: session.endTime,
    //           transitionTo: session?.transition,
    //           uuid: session.uuid,
    //           slotId: session.slotId,
    //           _id: session._id,
    //         })
    //       );
    //     return updatedPartTimeSessions;
    //   });
    //   setHavePartTimeSessions(true);
    // }

    const sequentialClassroom = resp?.data?.existingClassroomSetups.filter(
      (classroom: IClassroomSetup) => classroom.ageSequential === true
    );
    if (sequentialClassroom?.length > 0) {
      setClassrooms(sequentialClassroom);
    } else {
      setClassrooms([initialNewClassroom]);
    }

    const nonSequentialClassroom = resp?.data?.existingClassroomSetups.filter(
      (classroom: IClassroomSetup) => classroom.ageSequential === false
    );

    setClassroomOptions(formattedClassrooms);

    if (nonSequentialClassroom?.length > 0) {
      const baClassrooms = nonSequentialClassroom.filter(
        (classroom: any) => classroom.BAProgram === true
      );
      setBAClassrooms(baClassrooms);
      handleSetNumberOfClassrooms(nonSequentialClassroom);
      setNonAgeSequentialClassrooms(nonSequentialClassroom);
    } else {
      setNonAgeSequentialClassrooms([]); // setNonAgeSequentialClassrooms([initialNewClassroom]);
    }
  };

  // Validate classrooms whenever the classrooms change
  useEffect(() => {
    // if (!classrooms?.length || !classrooms[0]?.classroomName) return;
    const { errors } = validateClassrooms(classrooms);
    setSeqValidationErrors(errors);
  }, [classrooms, editSequentialClasses]);

  useEffect(() => {
    // if (!nonAgeSequentialClassrooms?.length || !nonAgeSequentialClassrooms[0]?.classroomName) return;
    // const allClasses = [...classrooms, ...nonAgeSequentialClassrooms];
    const { errors } = validateClassrooms(
      nonAgeSequentialClassrooms,
      false,
      true
    );
    setNonSeqValidationErrors(errors);
  }, [nonAgeSequentialClassrooms, editNonSequentialClasses]);

  const handleInputChange = (
    name: string,
    value: string | number | SingleValue<IOptionS>,
    index: number
  ) => {
    setClassrooms((prev) => {
      const updatedClassrooms = [...prev];
      const classroom = { ...updatedClassrooms[index] };

      // Handle classroom name
      if (name === "classroomName") {
        classroom.classroomName = value as string;
      } else if (name === "TransitionClassroom") {
        // Handle ReactSelect transition classroom selection
        if (value) {
          const selectedTransition = value as IOptionS;
          classroom.transition = {
            ...classroom.transition,
            TransitionClassroom: selectedTransition.value,
          };
        } else {
          classroom.transition = {
            ...classroom.transition,
            TransitionClassroom: "",
          };
        }
      }
      // Handle minimum age fields
      else if (name.startsWith("minimumAge")) {
        const field = name.split(".").pop(); // gets 'days', 'months', or 'years'
        if (field) {
          classroom.ageRange.minimumAge = {
            ...classroom.ageRange.minimumAge,
            [field]: Number(value),
          };

          // Recalculate ageInDays for minimum age
          const ageInDays = convertToDays(
            classroom.ageRange.minimumAge.days,
            classroom.ageRange.minimumAge.months,
            classroom.ageRange.minimumAge.years
          );
          console.log("check ageInDays", ageInDays);
          classroom.ageRange.minimumAge.ageInDays = ageInDays;
          const { months, days, years } = calculateYearsMonthsDays(ageInDays);
          classroom.ageRange.minimumAge.days = days;
          classroom.ageRange.minimumAge.months = months;
          classroom.ageRange.minimumAge.years = years;
        }
      }
      // Handle maximum age fields
      else if (name.startsWith("maxAge")) {
        const field = name.split(".").pop(); // gets 'days', 'months', or 'years'
        if (field) {
          classroom.ageRange.maximumAge = {
            ...classroom.ageRange.maximumAge,
            [field]: Number(value),
          };

          // Recalculate ageInDays for maximum age
          // classroom.ageRange.maximumAge.ageInDays = convertToDays(
          //   classroom.ageRange.maximumAge.days,
          //   classroom.ageRange.maximumAge.months,
          //   classroom.ageRange.maximumAge.years
          // );
          const ageInDays = convertToDays(
            classroom.ageRange.maximumAge.days,
            classroom.ageRange.maximumAge.months,
            classroom.ageRange.maximumAge.years
          );
          classroom.ageRange.maximumAge.ageInDays = ageInDays;
          const { months, days, years } = calculateYearsMonthsDays(ageInDays);
          // classroom.ageRange.maximumAge.ageInDays = days;
          classroom.ageRange.maximumAge.days = days;
          classroom.ageRange.maximumAge.months = months;
          classroom.ageRange.maximumAge.years = years;
        }
      }

      updatedClassrooms[index] = classroom;
      return updatedClassrooms;
    });
  };

  // const handleAddPartTimeSessions = (
  //   slotIds: string[],
  //   index: number,
  //   isSequential: boolean
  // ) => {
  //   const updateClassrooms = (tempClassrooms: typeof classrooms) => {
  //     console.log("check ids: ", tempClassrooms)
  //     return tempClassrooms?.map((classroom, idx) => {
  //       if (idx !== index) return classroom;

  //       if (slotIds.length > 0) {
  //         return {
  //           ...classroom,
  //           partTimeSlots: partTimeSessions?.map((session, i) => ({
  //             ...session,
  //             _id: classroom?.partTimeSlots?.[i]?._id || session._id,
  //             uuid: classroom?.partTimeSlots?.[i]?.uuid || session.uuid,
  //             transitionTo:
  //               classroom?.partTimeSlots?.[i]?.transition || session.transition,
  //           })),
  //           settingType: SessionType.FullAndPartTime,
  //         };
  //       } else {
  //         return {
  //           ...classroom,
  //           partTimeSlots: [],
  //           settingType: SessionType.FullTimeOnly,
  //         };
  //       }
  //     });
  //   };

  //   if (isSequential) {
  //     console.log("check ids: ", slotIds, isSequential)
  //     setClassrooms((prev) => {
  //       if (!prev) {
  //         console.error("Classrooms data is undefined.");
  //         return [];
  //       }
  //       return updateClassrooms(prev);
  //     });
  //   }
  //   // else {
  //   //   setNonAgeSequentialClassrooms((prev) => {
  //   //     if (!prev) {
  //   //       console.error("Non-age sequential classrooms data is undefined.");
  //   //       return [];
  //   //     }
  //   //     return updateClassrooms(prev);
  //   //   });
  //   // }
  // };

  const handleAddPartTimeSessions = (
    slotIds: string[],
    index: number,
    isSequential: boolean
  ) => {
    const updateClassrooms = (tempClassrooms: typeof classrooms) => {
      return tempClassrooms.map((classroom, idx) => {
        if (idx !== index) return classroom; // Only modify the targeted classroom

        // Filter part-time sessions based on selected slotIds
        const filteredSlots = partTimeSessions?.filter((session) =>
          slotIds?.includes(session.slotId as string)
        );
        console.log("filtered slots: ", filteredSlots);
        return {
          ...classroom,
          partTimeSlots: filteredSlots?.map((slot) => ({
            ...slot,
            startTime: slot.startTime,
            endTime: slot.endTime,
            name: slot.name,
            _id: slot._id,
            uuid: slot.uuid,
            slotId: slot.slotId,
            transitionTo: slot.transition,
          })), // Assign only selected part-time slots
          settingType:
            slotIds.length > 0
              ? SessionType.FullAndPartTime
              : SessionType.FullTimeOnly,
        };
      });
    };

    if (isSequential) {
      setClassrooms((prev) => (prev ? updateClassrooms(prev) : []));
    } else {
      setNonAgeSequentialClassrooms((prev) =>
        prev ? updateClassrooms(prev) : []
      );
    }
  };

  const handleAddClassroom = (index: number) => {
    setClassrooms((prev: any) => {
      // Ensure index is within bounds
      if (index < 0 || index >= prev?.length) {
        showErrorToast("Invalid index provided for adding a new classroom.");
        return prev;
      }

      // Check if the specified classroom has a valid maximum age
      const currentClassroom = prev[index];
      const hasValidMaxAge = currentClassroom.ageRange.maximumAge.ageInDays > 0;

      if (!hasValidMaxAge) {
        showErrorToast(
          "Please set the maximum age for the specified classroom first."
        );
        return prev;
      }

      const currentMaxAge = currentClassroom.ageRange.maximumAge;

      // Calculate total days of the specified classroom's maximum age
      const currentTotalDays = convertToDays(
        currentMaxAge.days,
        currentMaxAge.months,
        currentMaxAge.years
      );

      // Convert (currentTotalDays + 1) to years, months, and days
      const newMinimumAge = calculateYearsMonthsDays(currentTotalDays + 1);

      // Create new classroom with calculated minimum age
      const newClassroom = {
        classroomName: "",
        ageRange: {
          minimumAge: {
            ...newMinimumAge,
            ageInDays: currentTotalDays + 1,
            countAllowedOutsideRange: 0,
          },
          maximumAge: {
            years: 0,
            months: 0,
            days: 0,
            ageInDays: 0,
            countAllowedOutsideRange: 0,
          },
        },
      };

      // Insert the new classroom at the specified index + 1
      const updatedClassrooms = [...prev];
      updatedClassrooms?.splice(index + 1, 0, newClassroom);

      return updatedClassrooms;
    });
  };

  const handleRemoveClassroom = (index: number) => {
    setClassrooms((prev) => {
      // Check if index is valid
      if (index < 0 || index >= prev?.length) {
        console.error("Invalid index for classroom removal");
        return prev;
      }

      // Prevent removing the last classroom
      if (prev?.length <= 1) {
        showErrorToast("At least one classroom is required");
        return prev;
      }

      // Remove the classroom at the specified index
      return prev?.filter((_, i) => i !== index);
    });
  };

  const handleOpenSaveModal = (classroom: string) => {
    setSavingClassrooms(classroom);
    setIsOpenReviewModal(true);
  };
  const handleCloseSaveModal = () => {
    setSavingClassrooms("");
    setIsOpenReviewModal(false);
  };
  const { updatePartTimeSlots } = useUpdatePartTimeSlots();
  const handleSavePartTimeSessions = async () => {
    let reqBody: any = { partTimeSlots: [] };
    if (partTimeSessions?.length === 0 && !havePartTimeSessions) {
      reqBody = { partTimeSlots: [] };
    } else {
      reqBody = {
        partTimeSlots: partTimeSessions?.map((session) => ({
          slotId: session.slotId || "",
          name: session.name,
          startTime: session.startTime,
          endTime: session.endTime,
        })),
      };
    }
    const res = await updatePartTimeSlots(reqBody);
    if (res?.ok) {
      console.log("check res of updatePartTimeSlots: ", res?.data);
    }
  };
  const { postEssentialClassroomSetup } = usePostEssentialClassrooms();
  const handleSave = async () => {
    if (!classrooms?.length || !classrooms[0]?.classroomName) return;
    const { isValid, errors } = validateClassrooms(classrooms);
    if (!isValid) {
      setSeqValidationErrors(errors);
      showErrorToast("Please correct the errors before saving");
      // errors.map((error) => showErrorToast(error));
      return;
    }

    const checkedClassrooms = classrooms?.map((classroom) => {
      const { transition, ...rest } = classroom;

      // Exclude the transition key if TransitionClassroom is empty or "none"
      if (
        !transition?.TransitionClassroom ||
        transition?.TransitionClassroom === "none"
      ) {
        return {
          ...rest,
          // partTimeSlots:
          //   classroom?.partTimeSlots?.length > 0 && !classroom?.BAProgram
          //     ? partTimeSessions
          //     : [],
        };
      }

      return {
        ...classroom,
        // partTimeSlots:
        //   classroom?.partTimeSlots?.length > 0 && !classroom?.BAProgram
        //     ? partTimeSessions
        //     : [],
      };
    });

    const existingClassrooms = checkedClassrooms.filter(
      (classroom: any) => classroom._id
    );
    const newlyAddedClassrooms = checkedClassrooms.filter(
      (classroom: any) => classroom._id === undefined
    );

    const payload: IEssentialClassroomSetup = {
      newClassrooms: newlyAddedClassrooms,
      editedClassrooms: existingClassrooms,
      numberOfBAClassrooms: baProgramInfo?.numberOfClassrooms || 0,
      numberOfSummerClassrooms: summerProgramInfo?.numberOfClassrooms || 0,
    };

    const resp: any = await postEssentialClassroomSetup({
      payload,
      sequential: true,
    });

    if (resp?.ok) {
      fetchClassrooms();
      showSuccessToast(toastMessage.Updated_Successfully);
      handleCloseSaveModal();
      setEditSequentialClasses(false);
    } else if (
      resp?.error.statusCode === 400 &&
      resp?.error?.message?.includes(
        "Cannot remove part-time slots as there are students assigned to the following"
      )
    ) {
      showErrorToast(resp?.error?.message);
      setIsOpenReviewModal(false);
      setIsConfirmationModalOpen(true);
    } else {
      showErrorToast(resp?.error.message);
    }
  };

  const handleInputChangeNonSeq = (
    name: string,
    value: string | number | SingleValue<IOptionS>,
    index: number
  ) => {
    setNonAgeSequentialClassrooms((prev) => {
      const updatedClassrooms = [...prev];
      const classroom = { ...updatedClassrooms[index] };

      if (name === "classroomName") {
        classroom.classroomName = value as string;
      } else if (name === "TransitionClassroom") {
        // Handle ReactSelect transition classroom selection
        if (value) {
          const selectedTransition = value as IOptionS;
          classroom.transition = {
            ...classroom.transition,
            ageInDays: classroom.ageRange.maximumAge.ageInDays + 1,
            days: classroom.ageRange.maximumAge.days + 1,
            months: classroom.ageRange.maximumAge.months,
            years: classroom.ageRange.maximumAge.years,
            TransitionClassroom: selectedTransition.value,
          };
        } else {
          classroom.transition = {
            ...classroom.transition,
            TransitionClassroom: "",
          };
        }
      } else if (name.startsWith("minimumAge")) {
        const field = name.split(".").pop();
        if (field) {
          classroom.ageRange.minimumAge = {
            ...classroom.ageRange.minimumAge,
            [field]: Number(value),
          };

          const ageInDays = convertToDays(
            classroom.ageRange.minimumAge.days,
            classroom.ageRange.minimumAge.months,
            classroom.ageRange.minimumAge.years
          );
          classroom.ageRange.minimumAge.ageInDays = ageInDays;

          const { months, days, years } = calculateYearsMonthsDays(ageInDays);
          classroom.ageRange.minimumAge.days = days;
          classroom.ageRange.minimumAge.months = months;
          classroom.ageRange.minimumAge.years = years;
        }
      } else if (name.startsWith("maxAge")) {
        const field = name.split(".").pop();
        if (field) {
          classroom.ageRange.maximumAge = {
            ...classroom.ageRange.maximumAge,
            [field]: Number(value),
          };

          const ageInDays = convertToDays(
            classroom.ageRange.maximumAge.days,
            classroom.ageRange.maximumAge.months,
            classroom.ageRange.maximumAge.years
          );
          classroom.ageRange.maximumAge.ageInDays = ageInDays;

          const { months, days, years } = calculateYearsMonthsDays(ageInDays);
          classroom.ageRange.maximumAge.days = days;
          classroom.ageRange.maximumAge.months = months;
          classroom.ageRange.maximumAge.years = years;

          // Calculate transition age (+1 day from maximum age)
          const transitionAgeInDays = ageInDays + 1;
          const transitionAge = calculateYearsMonthsDays(transitionAgeInDays);

          classroom.transition = {
            ...classroom.transition,
            years: transitionAge.years,
            months: transitionAge.months,
            days: transitionAge.days,
            ageInDays: transitionAgeInDays,
          };
        }
      }

      updatedClassrooms[index] = classroom;
      return updatedClassrooms;
    });
  };

  const handleOpenTransitionClassroomModal = () =>
    setTransitionSlotModalOpen(true);
  const handleCloseTransitionClassroomModal = () =>
    setTransitionSlotModalOpen(false);

  const handleTransitionLastClassroomSave = () => {
    handleOpenSaveModal("sequential");
  };

  const handleSequentialSave = () => {
    const transitionClassroom =
      classrooms[classrooms.length - 1]?.transition?.TransitionClassroom;
    if (!transitionClassroom || transitionClassroom === "none") {
      handleOpenTransitionClassroomModal();
    } else handleOpenSaveModal("sequential");
  };
  const handleNonSequentialSave = () => {
    handleOpenSaveModal("non-sequential");
  };

  const handleNonSeqClassesSave = async () => {
    if (
      !nonAgeSequentialClassrooms?.length ||
      !nonAgeSequentialClassrooms[0]?.classroomName
    )
      return;
    const { isValid, errors } = validateClassrooms(
      nonAgeSequentialClassrooms,
      false,
      true
    );

    if (!isValid) {
      setNonSeqValidationErrors(errors);
      showErrorToast("Please correct the errors before saving");
      // errors.map((error) => showErrorToast(error));

      return;
    }
    // const checkedNonSeqClassrooms = nonAgeSequentialClassrooms?.map(
    //   (classroom) => ({
    //     ...classroom,
    //     partTimeSlots:
    //       classroom?.partTimeSlots?.length > 0 && !classroom?.BAProgram
    //         ? partTimeSessions
    //         : [],
    //   })
    // );

    // Filter out `transitions` if `TransitionClassroom` is empty
    const processedClassrooms = nonAgeSequentialClassrooms?.map((classroom) => {
      const { transition, ...rest } = classroom;
      if (!transition?.TransitionClassroom) {
        return rest; // Exclude `transitions` key
      }
      return classroom; // Include the full object with `transitions`
    });

    const existingClassrooms = processedClassrooms.filter(
      (classroom: any) => classroom._id
    );

    const newlyAddedClassrooms = processedClassrooms.filter(
      (classroom: any) => classroom._id === undefined
    );

    const payload = {
      newClassrooms: newlyAddedClassrooms,
      editedClassrooms: existingClassrooms,
      numberOfBAClassrooms: baProgramInfo?.numberOfClassrooms || 0,
      numberOfSummerClassrooms: summerProgramInfo?.numberOfClassrooms || 0,
    };
    // const resp = await classroomSvc.createEssentialClassrooms(payload, false);
    const resp: any = await postEssentialClassroomSetup({
      payload,
      sequential: false,
    });
    if (resp?.ok) {
      fetchClassrooms();
      showSuccessToast(toastMessage.Updated_Successfully);
      handleCloseSaveModal();
      setEditNonSequentialClasses(false);
    } else if (
      resp?.error.statusCode === 400 &&
      resp?.error?.message?.includes(
        "Cannot remove part-time slots as there are students assigned to the following"
      )
    ) {
      showErrorToast(resp?.error?.message);
      setIsOpenReviewModal(false);
      setIsConfirmationModalOpen(true);
    } else {
      // fetchClassrooms();
      showErrorToast(resp?.error.message);
    }
  };

  const handleAddNonSeqClass = (index: number) => {
    setNonAgeSequentialClassrooms((prev) => {
      // Ensure index is within bounds
      if (index < 0 || index >= prev?.length) {
        showErrorToast("Invalid index provided for adding a new classroom.");
        return prev;
      }

      // Create new classroom with empty age range
      const newClassroom: any = {
        classroomName: "",
        ageRange: {
          minimumAge: {
            years: 0,
            months: 0,
            days: 0,
            ageInDays: 0,
            countAllowedOutsideRange: 0,
          },
          maximumAge: {
            years: 0,
            months: 0,
            days: 0,
            ageInDays: 0,
            countAllowedOutsideRange: 0,
          },
        },
      };

      // Insert the new classroom at the specified index + 1
      const updatedClassrooms = [...prev];
      updatedClassrooms?.splice(index + 1, 0, newClassroom);

      return updatedClassrooms;
    });
  };

  const handleRemoveNonSeqClass = (index: number) => {
    setNonAgeSequentialClassrooms((prev) => {
      if (index < 0 || index >= prev?.length) {
        console.error("Invalid index for classroom removal");
        return prev;
      }

      // Prevent removing the last classroom
      if (prev?.length <= 1) {
        showErrorToast("At least one classroom is required");
        return prev;
      }

      // Remove the classroom at the specified index
      return prev?.filter((_, i) => i !== index);
    });
  };

  const handleCancel = () => {
    fetchClassrooms();
  };

  const handleCancelPartTimeSession = () => {
    if (center?.existingCenterSetup?.partTimeSlots?.length > 0) {
      setPartTimeSessions(center?.existingCenterSetup?.partTimeSlots);
      setHavePartTimeSessions(true);
    } else {
      setPartTimeSessions([]);
      setHavePartTimeSessions(false);
    }
  };

  useEffect(() => {
    if (!center) return;
    fetchClassrooms();
    getCenterSetup();
  }, [center]);

  useEffect(() => {
    //this is to un-assign part time slots of a classroom
    if (havePartTimeSessions) return;

    const updateAllClassrooms = (tempClassrooms: typeof classrooms) => {
      return tempClassrooms?.map((classroom, index) => {
        // if (classroomData[index]?.BAProgram) {
        //   showWarningToast(
        //     "Can't assign part-time sessions as this classroom has a BA program. If you want to assign these sessions, change the classroom settings from detailed classroom setup."
        //   );
        //   return classroom;
        // }

        return {
          ...classroom,
          partTimeSlots: [], // Clear partTimeSlots for all classrooms
          settingType: SessionType.FullTimeOnly, // Update settingType
        };
      });
    };

    setClassrooms((prev) => updateAllClassrooms(prev));
    setNonAgeSequentialClassrooms((prev) => updateAllClassrooms(prev));
  }, [havePartTimeSessions]);

  const handleProceed = async () => {
    const tempClassrooms =
      savingClassrooms === "sequential"
        ? classrooms
        : nonAgeSequentialClassrooms;
    if (!tempClassrooms?.length || !tempClassrooms[0]?.classroomName) return;
    // Find classrooms where partTimeSlots has changed to an empty array
    const modifiedClassrooms = tempClassrooms?.filter((tempClassroom) => {
      const originalClassroom = classroomData?.find(
        (item) => item._id === tempClassroom._id
      );

      // Compare partTimeSlots
      return (
        originalClassroom &&
        originalClassroom.partTimeSlots?.length > 0 &&
        tempClassroom.partTimeSlots?.length === 0
      );
    });

    if (modifiedClassrooms?.length > 0) {
      // Handle the modified classrooms with empty partTimeSlots
      for (const classroom of modifiedClassrooms) {
        if (classroom?._id)
          await studentSvc.moveStudentsToFullTime(classroom?._id);
        else {
          showErrorToast(
            `Something went wrong while moving students to full time in ${classroom?.classroomName}`
          );
          return;
        }
      }
    }
    if (savingClassrooms === "sequential") handleSave();
    else handleNonSeqClassesSave();
  };

  const handleSaveNext = () => {
    navigate(forayRoutes.classroom_detailed);
  };

  useEffect(() => {
    if (
      !editSequentialClasses &&
      !editNonSequentialClasses &&
      classrooms?.length > 0
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [editSequentialClasses, editNonSequentialClasses, classrooms]);
  // console.log("check summer program info: ", nonAgeSequentialClassrooms);
  // console.log("check both classes: ", classrooms, nonAgeSequentialClassrooms);
  // console.log("check errors special: ", specialClassroomsErrors)
  return (
    <>
      <DashboardWrapper>
        <div className="px-6 py-5 bg-secondaryNeutral overflow-y-scroll h-[calc(100vh-85px)] custom-scrollbar">
          <Breadcrumb items={breadcrumbItems} />
          <BeforeAfterSchoolProgram
            hasBAProgram={hasBAProgram}
            hasSummerProgram={hasSummerProgram}
            baProgramInfo={baProgramInfo}
            summerProgramInfo={summerProgramInfo}
            toggleBAProgram={toggleBAProgram}
            toggleSummerProgram={toggleSummerProgram}
            handleInputBaProgram={handleInputBaProgram}
            handleInputSummerProgram={handleInputSummerProgram}
            addElementarySchool={addElementarySchool}
            removeElementarySchool={removeElementarySchool}
            addActivity={addActivity}
            removeActivity={removeActivity}
            handleSaveBAProgram={handleSavePrograms}
            handleCancelProgram={handleCancelProgram}
            specialClassroomsErrors={specialClassroomsErrors}
          />
          <PartTimeSession
            partTimeSessions={partTimeSessions}
            setPartTimeSessions={setPartTimeSessions}
            havePartTimeSessions={havePartTimeSessions}
            setHavePartTimeSessions={setHavePartTimeSessions}
            handleCancelPartTimeSession={handleCancelPartTimeSession}
            handleSave={handleSavePartTimeSessions}
          />
          <SetupInstruction />
          <EssentialInformation
            essentialInfo={classrooms}
            partTimeSessions={partTimeSessions}
            editSequentialClasses={editSequentialClasses}
            setEditSequentialClasses={setEditSequentialClasses}
            handleInputChange={handleInputChange}
            handleAddPartTimeSessions={handleAddPartTimeSessions}
            handleAddClassroom={handleAddClassroom}
            handleRemoveClassroom={handleRemoveClassroom}
            handleSave={handleSequentialSave}
            errors={seqValidationErrors}
            classroomOptions={classroomOptions}
            handleCancel={handleCancel}
          />
          <NonAgeEssential
            essentialInfo={nonAgeSequentialClassrooms ?? []}
            partTimeSessions={partTimeSessions}
            editNonSequentialClasses={editNonSequentialClasses}
            setEditNonSequentialClasses={setEditNonSequentialClasses}
            handleInputChange={handleInputChangeNonSeq}
            // handleAddPartTimeSessions={handleAddPartTimeSessions}
            handleAddClassroom={handleAddNonSeqClass}
            handleRemoveClassroom={handleRemoveNonSeqClass}
            handleSave={handleNonSequentialSave}
            classroomOptions={classroomOptions}
            errors={nonSeqValidationErrors}
            handleCancel={handleCancel}
          />
          <EssentialTransition
            classroomOptions={classroomOptions}
            classrooms={classroomData}
            autoTransition={autoTransition}
            toggleAutoTransition={toggleAutoTransition}
          />
          {/* <button className="btnPrimary max-w-[278px] mt-6">
            Save Transitions and Review
          </button> */}
          {/* <ConfirmationModal isOpen={true} onClose={() => {}} /> */}
          <SelectClassRoomModal
            isOpen={transitionSlotModalOpen}
            onClose={handleCloseTransitionClassroomModal}
            classroomOptions={classroomOptions}
            handleTransitionLastClassroomSave={
              handleTransitionLastClassroomSave
            }
            handleInputChange={handleInputChange}
            lastSeqClassroom={classrooms[classrooms.length - 1]}
            lastIndex={classrooms?.length - 1}
          />

          <SureToDeactivateModal
            isOpen={removeClassroomsSelectionModalOpen}
            onClose={handleCloseRemoveClassroomSelectionModal}
            baProgramInfo={baProgramInfo}
            summerProgramInfo={summerProgramInfo}
            allowedNumberOfClassroomsToRemove={
              allowedNumberOfClassroomsToRemove
            }
            handleArchiveSpecialClassroom={handleArchiveSpecialClassroom}
            classroomsToDelete={classroomsToDelete}
          />
          <ReviewEssentialClassroomsModal
            classrooms={
              savingClassrooms === "sequential"
                ? classrooms
                : nonAgeSequentialClassrooms
            }
            classroomOptions={classroomOptions}
            savingClassrooms={savingClassrooms}
            isOpen={isOpenReviewModal}
            onClose={handleCloseSaveModal}
            onSave={
              savingClassrooms === "sequential"
                ? handleSave
                : handleNonSeqClassesSave
            }
          />
          <button
            className={`h-[56px] w-full max-w-[298px] mt-6 ${
              isDisabled ? "bg-[#DADADA] text-white rounded-md" : "btnPrimary"
            }`}
            onClick={handleSaveNext}
            disabled={isDisabled}
          >
            Save & Next
          </button>
          <ConfirmationModal
            isOpen={isConfirmationModalOpen}
            onClose={() => setIsConfirmationModalOpen(false)}
            onProceed={handleProceed}
            message="This action cannot be undone. All selected students will be withdrawn from the program."
            actionText="You have unchecked part-time program but there are still students in the sessions. Do you want to transfer all students to full time?"
          />
        </div>
      </DashboardWrapper>
    </>
  );
};

export default EssentialClassroomSetup;
