import {
    useMutation,
    useQueryClient,
    UseMutationOptions,
  } from "@tanstack/react-query";
  import { CenterQueryKeyString } from "../enums/centerQueryKey.enum";
  import { foraySvc } from "../services/foray.service";
  import { showErrorToast, showSuccessToast } from "../../shared/utils/toast";
  import { useNavigate } from "react-router-dom";
  import { forayRoutes } from "../routes/routes";
  
  type UpdateCenterSetupResponse = {
    data: any;
    ok: boolean;
  };
  
  type UpdateCenterSetupParams = {
    reqBody: any;
    shouldNavigate?: boolean;
  };
  
  export const useUpdateCenterSetup = () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
  
    const mutationOptions: UseMutationOptions<
      UpdateCenterSetupResponse | undefined,
      Error,
      UpdateCenterSetupParams
    > = {
      mutationFn: async ({ reqBody }: UpdateCenterSetupParams) => {
        const res = await foraySvc.updateCenterSetup(reqBody);
        return res || undefined;
      },
      onSuccess: (res, { shouldNavigate }) => {
        if (res?.ok) {
          showSuccessToast("Center setup updated successfully.");
          queryClient.invalidateQueries({
            queryKey: [CenterQueryKeyString.CenterSetup],
          });
  
          if (shouldNavigate) {
            navigate(forayRoutes.center_view);
          }
        } else {
          showErrorToast("Error updating center setup");
        }
      },
    };
  
    const { mutateAsync } = useMutation(mutationOptions);
  
    return {
      updateCenterSetup: (reqBody: any, shouldNavigate: boolean = true) =>
        mutateAsync({ reqBody, shouldNavigate }),
    };
  };
  