import React from "react";
import MainWrapper from "../../components/layout/MainWrapper";
import { useNavigate } from "react-router-dom";
import { forayRoutes } from "../../../foray_module/routes/routes";
import { IoCheckmarkOutline } from "react-icons/io5";
import { GoArrowRight } from "react-icons/go";

const CheckOutSuccess = () => {
  const navigate = useNavigate();
  const handleContinue = () => [navigate(forayRoutes.foray_screen)];
  return (
    <MainWrapper>
      <div className=" flex justify-center items-center w-full min-h-[492px] md:h-full overflow-y-auto overflow-x-hidden bg-secondaryNeutral">
        <div className="bg-white px-24 py-9 rounded-3xl shadow-lg w-[653px] flex items-center justify-center flex-col">
          <i className="text-white bg-successAlert rounded-full h-20 w-20 mb-6 text-center flex items-center justify-center">
            <IoCheckmarkOutline className="w-14 h-14" />
          </i>
          <h2 className="text-2xl text-secondary font-semibold">
            Welcome to <span className="italic">Foray</span>! 🎉
          </h2>
          <p className="text-2xl text-secondary mb-12">
            You've successfully subscribed.
          </p>
          <button
            className="btnPrimary max-w-[160px] flex justify-center gap-2
                          focus:outline-none focus:ring-primary-300 "
            onClick={handleContinue}
          >
            Go to Foray
            <GoArrowRight className="w-5 h-5" />
          </button>
        </div>
        {/* <div className="relative p-4 text-center bg-gray-100 rounded-lg shadow sm:p-5 ">
                        <div className="w-12 h-12 rounded-full bg-green-100  p-2 flex items-center justify-center mx-auto mb-3.5">
                            <svg
                                aria-hidden="true"
                                className="w-8 h-8 text-green-500 "
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                    clipRule="evenodd"
                                ></path>
                            </svg>
                            <span className="sr-only">Success</span>
                        </div>
                        <p className="mb-4 text-lg font-semibold text-gray-900 ">
                            Subscription successful
                        </p>
                        <button
                            className="py-2 px-3 text-sm font-medium text-center bg-primary text-white rounded-lg bg-primary-600 hover:bg-primary focus:ring-4 focus:outline-none focus:ring-primary-300 "
                            onClick={handleContinue}
                        >
                            Continue
                        </button>
                    </div> */}
      </div>
    </MainWrapper>
  );
};

export default CheckOutSuccess;
