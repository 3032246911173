import React, { useState, useRef, useEffect } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { IOptionS } from "../../types/ICenterSetup.interface";
import RequiredAsterisk from "../../../shared/components/ui/RequiredAsterisk";

interface DropdownProps {
  options: IOptionS[];
  label?: string;
  placeholder: string;
  value: string[];
  onSelectDays: (selectedDays: string[]) => void;
  required?: boolean;
  disabled?: boolean;
  allowSelectAll?: boolean;
}

const DaysDropdown: React.FC<DropdownProps> = ({
  options,
  label,
  placeholder,
  value,
  onSelectDays,
  required = false,
  disabled,
  allowSelectAll = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]); // Initialize with value
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => setIsOpen((prev) => !prev);

  // Update selectedOptions when value changes dynamically
  useEffect(() => {
    if (value?.length > 0 && !!value[0])
      setSelectedOptions(value);
  }, [value]);
  const handleCheckboxChange = (selectedValue: string) => {
    let updatedSelection: string[];
    if (selectedValue === "select_all") {
      updatedSelection =
        selectedOptions.length === options.length ? [] : options.map((opt) => opt.value);
    } else {
      updatedSelection = selectedOptions.includes(selectedValue)
        ? selectedOptions.filter((item) => item !== selectedValue)
        : [...selectedOptions, selectedValue];

      // If all options are selected, mimic "Select All" behavior
      if (updatedSelection?.length === options?.length) {
        updatedSelection = options?.map((opt) => opt.value);
      }
    }

    setSelectedOptions(updatedSelection);
    onSelectDays(updatedSelection); // Update parent state immediately
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className="relative w-full" ref={dropdownRef}>
      <label className="text-sm text-secondaryVariant">
        {label || ""}
        {required && <RequiredAsterisk />}
      </label>
      <div className="relative">
        <button
          type="button"
          onClick={toggleDropdown}
          disabled={disabled}
          className={`w-full bg-white border border-gray-300 rounded-lg shadow-sm px-4 py-3.5 text-left 
            focus:outline-none flex justify-between items-center ${disabled ? "opacity-50" : "cursor-pointer"
            }`}
        >
          <span className="truncate">
            {selectedOptions?.length > 0
              ? options
                .filter((option) => selectedOptions.includes(option.value))
                .map((option) => option.label)
                .join(", ")
              : placeholder}
          </span>
          <span className="ml-2">
            {isOpen ? <FaChevronUp className="text-gray-600" /> : <FaChevronDown className="text-gray-600" />}
          </span>
        </button>
        {isOpen && (
          <div className="dropdownMenu absolute z-10 mt-1 w-full bg-white border border-gray-300 rounded-lg shadow-lg">
            {allowSelectAll && (
              <div className="flex items-center px-4 py-2 hover:bg-gray-100">
                <input
                  type="checkbox"
                  id="select_all"
                  checked={selectedOptions.length === options.length}
                  onChange={() => handleCheckboxChange("select_all")}
                  className="mr-2 w-4 h-4 border-secondaryVariant2 border rounded"
                />
                <label htmlFor="select_all" className="cursor-pointer font-semibold">
                  Select All
                </label>
              </div>
            )}
            {options.map((option) => (
              <div key={option.value} className="flex items-center px-4 py-2 hover:bg-gray-100">
                <input
                  type="checkbox"
                  id={option.value}
                  checked={selectedOptions.includes(option.value)}
                  onChange={() => handleCheckboxChange(option.value)}
                  className="mr-2 w-4 h-4 border-secondaryVariant2 border rounded"
                />
                <label htmlFor={option.value} className="cursor-pointer">
                  {option.label}
                </label>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default DaysDropdown;