import { useQuery } from "@tanstack/react-query";
import { classroomSvc } from "../services/classroom.service";
import { IClassroomSetup } from "../types/IClassroomSetup.interface";

interface UseClassroomsResult {
  classrooms: IClassroomSetup[];
  hasClassroomWithUnfilledDetails: boolean;
}

export const useClassrooms = (applySelect: boolean = true) => {
  return useQuery<IClassroomSetup[], unknown, UseClassroomsResult>({
    queryKey: ["classrooms"],
    queryFn: async () => {
      const res = await classroomSvc.fetchClassrooms();
      return res?.ok ? res.data?.existingClassroomSetups ?? [] : [];
    },
    staleTime: 0, // Always fetch fresh data, no caching
    refetchOnMount: true, // Fetch every time the component mounts
    refetchOnWindowFocus: true,

    select: (classrooms) => {
      if (!applySelect) {
        return { classrooms, hasClassroomWithUnfilledDetails: false };
      }

      const withoutDetailClassroom = classrooms.find(
        (classroom) =>
          !classroom?.nickName ||
          !classroom?.capacity?.licenseCapacity ||
          !classroom?.capacity?.physicalCapacity ||
          !classroom?.capacity?.studentPerTeacher ||
          !classroom?.settingType
      );

      return {
        classrooms,
        hasClassroomWithUnfilledDetails: !!withoutDetailClassroom,
      };
    },
  });
};
