import moment from "moment";
import React from "react";
import { IoCloseOutline } from "react-icons/io5";
import Select from "react-select";
import { showErrorToast } from "../../../shared/utils/toast";
import { weekdays } from "../../constants/weekDaysOptions";
import { IDailyReqBody } from "../../types/IChecklist.interface";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  checklist: IDailyReqBody | undefined;
  onChecklistChange: (field: keyof IDailyReqBody, value: any) => void;
  mutate: any;
  isEdit?: boolean;
  centerSetup: any;
}

const AddDailyTaskModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  onChecklistChange,
  mutate,
  checklist,
  isEdit,
  centerSetup,
}) => {
  console.log("checklist", checklist);
  if (!isOpen) return null;

  const startTime = moment(
    centerSetup?.existingCenterSetup?.operatingHours?.startTime,
    "hh:mm A"
  ).format("HH:mm");
  const endTime = moment(
    centerSetup?.existingCenterSetup?.operatingHours?.endTime,
    "hh:mm A"
  ).format("HH:mm");
  const validateTimeSelection = (timeValue: string) => {
    const selectedTime = moment(timeValue, "HH:mm");
    const start = moment(startTime, "HH:mm");
    const end = moment(endTime, "HH:mm");

    return selectedTime.isBetween(start, end, undefined, "[]"); // '[]' includes the start and end times
  };

  const filteredWeekdays = weekdays.filter((day) =>
    centerSetup?.existingCenterSetup?.operatingHours?.days.includes(day.value)
  );

  return (
    <>
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-[9999]">
        <div className="bg-white rounded-lg shadow-lg w-[464px]">
          <div className="flex items-center justify-between py-4 px-6">
            <h2 className="text-2xl font-semibold text-secondary">
              {isEdit ? "Edit" : "Add"} Task
            </h2>
            <button
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700"
            >
              {" "}
              <IoCloseOutline className="w-6 h-6 text-secondary" />
            </button>
          </div>
          <div className="py-4 px-6 space-y-[18px]">
            {/* <div>
              <label className="text-[10px] text-secondaryVariant mb-0.5">
                Time (Optimal time to execute tasks)*
              </label>
              <input
                type="time"
                className="bg-[#F6F9FB] text-base text-secondaryVariant w-full h-[40px] rounded px-2"
                value={
                  checklist?.time
                    ? moment(checklist.time, "hh:mm A").format("HH:mm")
                    : ""
                }
                onChange={(e) => {
                  const timeValue = e.target.value;
                  const formattedTime = moment(timeValue, "HH:mm").format(
                    "hh:mm A"
                  );
                  onChecklistChange("time", formattedTime);
                }}
              />
            </div> */}
            <div>
              <label className="text-[10px] text-secondaryVariant mb-0.5">
                Time (Optimal time to execute tasks)*
              </label>
              <input
                type="time"
                className="bg-[#F6F9FB] text-base text-secondaryVariant w-full h-[40px] rounded px-2"
                min={startTime}
                max={endTime}
                value={
                  checklist?.time
                    ? moment(checklist.time, "hh:mm A").format("HH:mm")
                    : ""
                }
                onChange={(e) => {
                  const timeValue: any = e.target.value;
                  if (validateTimeSelection(timeValue)) {
                    const formattedTime = moment(timeValue, "HH:mm").format(
                      "hh:mm A"
                    );
                    onChecklistChange("time", formattedTime);
                  } else {
                    showErrorToast(
                      `Please select a time between ${centerSetup?.existingCenterSetup?.operatingHours?.startTime} and ${centerSetup?.existingCenterSetup?.operatingHours?.endTime}`
                    );
                  }
                }}
              />
              {/* <span className="text-xs text-gray-500">
                Operating hours:{" "}
                {centerSetup?.existingCenterSetup?.operatingHours?.startTime} -{" "}
                {centerSetup?.existingCenterSetup?.operatingHours?.endTime}
              </span> */}
            </div>
            <div>
              <label
                htmlFor="select"
                className="text-base text-secondaryVariant block"
              >
                Day
              </label>
              <Select
                className="newTask bg-[#F6F9FB] rounded h-10 ps-3"
                placeholder={"Select day"}
                options={filteredWeekdays}
                value={filteredWeekdays.find(
                  (option) => option.value === checklist?.dayOfWeek
                )}
                onChange={(selectedOption: any) =>
                  onChecklistChange("dayOfWeek", selectedOption.value)
                }
              />
            </div>
            <div>
              <label className="text-[10px] text-secondaryVariant mb-0.5">
                Task*{" "}
              </label>
              <input
                type="text"
                placeholder="Task"
                className="bg-[#F6F9FB] text-base text-secondaryVariant w-full h-[40px] rounded px-2"
                value={checklist?.task}
                onChange={(e) => onChecklistChange("task", e.target.value)}
              />
            </div>
          </div>

          <div className="flex items-center justify-end gap-4 py-4 px-6">
            <button
              className="text-sm font-medium text-secondaryVariant2"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              className="btnPrimary max-w-[95px]"
              onClick={() => {
                if (checklist) {
                  if (!checklist.time || !checklist.task) {
                    showErrorToast("Please fill all the fields");
                    return;
                  }
                  isEdit
                    ? mutate({ itemId: checklist?._id, reqBody: checklist })
                    : mutate(checklist);
                  onClose();
                }
              }}
            >
              {isEdit ? "Update" : "Add"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddDailyTaskModal;
