import { lazy, Suspense } from "react";
import { RouteObject } from "react-router-dom";
import LoaderSpinner from "../shared/components/Loader/Loader";
import { forayRoutes } from "./routes/routes";
// import ViewStudentProfile from "./pages/viewStudentProfile/ViewStudentProfile";
import AddInterview from "../hr-setup_modules/pages/addInterview/AddInterview";
import ConductViolationsSetup from "../hr-setup_modules/pages/conductViolations/ConductViolationsSetup";
import EmployeeEvaluationsSetup from "../hr-setup_modules/pages/employeeEvaluations/EmployeeEvaluationsSetup";
import HandBook from "../hr-setup_modules/pages/handbook/HandBook";
import HrDashboard from "../hr-setup_modules/pages/hr/HrDashboard";
import OvertimePayroll from "../hr-setup_modules/pages/overtime/OvertimePayroll";
import EmployeePersonalInformation from "../hr-setup_modules/pages/personalInformation/EmployeePersonalInformation";
import ProfileInformation from "../hr-setup_modules/pages/profile-information/ProfileInformation";
import PtoTardySetup from "../hr-setup_modules/pages/pTOTardySetup/PtoTardySetup";
import EditStudentDetail from "./enrollment_Tracking/pages/enrollmentTracking/EditStudentDetail";
import EnrollmentTracking from "./enrollment_Tracking/pages/enrollmentTracking/EnrollmentTracking";
import CheckList from "./pages/checklist/CheckList";
import ClassroomDetailed from "./pages/classroomDetailed/ClassroomDetailed";
import DailySchedule from "./pages/dailyScheduleScreen/DailySchedule";
import EssentialClassroomList from "./pages/essentialClassroomList/EssentialClassroomList";
import EssentialClassroomSetup from "./pages/essentialClassroomSetup/EssentialClassroomSetup";
import ForayDashboard from "./pages/foray/ForayDashboard";
import ForayScreen from "./pages/foray/ForayScreen";
import FrLandingScreen from "./pages/foray/FrLandingScreen";
import ModifiedClassRoom from "./pages/modifyClassroom/ModifiedClassRoom";
import ParentInfo from "./pages/parentInfo/ParentInfo";
import TaskGeneration from "./pages/taskGeneration/TaskGeneration";
const CenterSetup = lazy(() => import("./pages/centerSetup/CenterSetup"));
const ForayCenterView = lazy(
  () => import("./pages/centerView/ForayCenterView")
);
const ClassroomSetup = lazy(
  () => import("./pages/classroomSetup/ClassroomSetup")
);
const ClassroomList = lazy(() => import("./pages/classroomList/ClassroomList"));
const ViewClassroom = lazy(() => import("./pages/viewClassroom/ViewClassroom"));
const StudentSetup = lazy(() => import("./pages/studentSetup/StudentSetup"));
const StudentList = lazy(() => import("./pages/studentList/StudentList"));
const ViewStudentProfile = lazy(
  () => import("./pages/viewStudentProfile/ViewStudentProfile")
);

const ForayModuleRoutes: RouteObject[] = [
  {
    path: forayRoutes.center_setup,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <CenterSetup />
      </Suspense>
    ),
  },
  {
    path: forayRoutes.center_modify,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <CenterSetup />
      </Suspense>
    ),
  },
  {
    path: forayRoutes.center_view,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <ForayCenterView />
      </Suspense>
    ),
  },
  {
    path: forayRoutes.essential_classroom_setup,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <EssentialClassroomSetup />
      </Suspense>
    ),
  },
  {
    path: forayRoutes.essential_classroom_list,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <EssentialClassroomList />
      </Suspense>
    ),
  },
  {
    path: forayRoutes.classroom_setup,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <ClassroomSetup />
      </Suspense>
    ),
  },
  {
    path: forayRoutes.classroom_modify,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <ClassroomSetup />
      </Suspense>
    ),
  },
  {
    path: forayRoutes.classroom_list,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <ClassroomList />
      </Suspense>
    ),
  },
  {
    path: forayRoutes.view_classroom,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <ViewClassroom />
      </Suspense>
    ),
  },
  {
    path: forayRoutes.student_setup,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <StudentSetup />
      </Suspense>
    ),
  },
  // {
  //   path: forayRoutes.student_modify,
  //   element: (
  //     <Suspense fallback={<LoaderSpinner />}>
  //       <StudentSetup />
  //     </Suspense>
  //   ),
  // },
  {
    path: forayRoutes.sibling_setup,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <StudentSetup />
      </Suspense>
    ),
  },
  {
    path: forayRoutes.student_list,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <StudentList />
      </Suspense>
    ),
  },
  {
    path: forayRoutes.view_student_profile,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <ViewStudentProfile />
      </Suspense>
    ),
  },
  {
    path: forayRoutes.parent_info,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <ParentInfo />
      </Suspense>
    ),
  },
  {
    path: forayRoutes.enrollment_tracking,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <EnrollmentTracking />
      </Suspense>
    ),
  },
  {
    path: forayRoutes.foray_screen,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <ForayScreen />
      </Suspense>
    ),
  },
  {
    path: forayRoutes.employee_personal_information,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <EmployeePersonalInformation />
      </Suspense>
    ),
  },
  {
    path: forayRoutes.add_interview,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <AddInterview />
      </Suspense>
    ),
  },

  {
    path: forayRoutes.student_detail_edit,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <EditStudentDetail />
      </Suspense>
    ),
  },

  {
    path: forayRoutes.hr_dashboard,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <HrDashboard />
      </Suspense>
    ),
  },

  // {
  //   path: forayRoutes.employee_personal_information,
  //   element: (
  //     <Suspense fallback={<LoaderSpinner />}>
  //       <EmployeeInformationForm />
  //     </Suspense>
  //   ),
  // },
  {
    path: forayRoutes.employee_details,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <ProfileInformation />
      </Suspense>
    ),
  },

  {
    path: forayRoutes.classroom_detailed,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <ClassroomDetailed />
      </Suspense>
    ),
  },
  {
    path: forayRoutes.detailed_classroom_setup,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <ModifiedClassRoom />
      </Suspense>
    ),
  },
  {
    path: forayRoutes.checkList,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <CheckList />
      </Suspense>
    ),
  },
  {
    path: forayRoutes.foray_dashboard,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <ForayDashboard />
      </Suspense>
    ),
  },
  
  {
    path: forayRoutes.task_generation,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <TaskGeneration />
      </Suspense>
    ),
  },
  {
    path: forayRoutes.foray,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <FrLandingScreen />
      </Suspense>
    ),
  },
  {
    path: forayRoutes.conduct_violations_setup,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <ConductViolationsSetup />
      </Suspense>
    ),
  },
  {
    path: forayRoutes.employee_evaluations_setup,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <EmployeeEvaluationsSetup />
      </Suspense>
    ),
  },
  {
    path: forayRoutes.overtime_payroll,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <OvertimePayroll />
      </Suspense>
    ),
  },
  {
    path: forayRoutes.pto_tardy_setup,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <PtoTardySetup />
      </Suspense>
    ),
  },
  {
    path: forayRoutes.handbook,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <HandBook />
      </Suspense>
    ),
  },
  {
    path: forayRoutes.daily_schedule,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <DailySchedule />
      </Suspense>
    ),
  },
];

export default ForayModuleRoutes;
