import {
  PartTimeSlotNames,
  SchoolAgeProgram,
  SessionType,
  Slot,
} from "../enums/Foray.enums";
import { IElementarySchool } from "../types/ICenterSetup.interface";
import {
  IBasicInfoClassDetailed,
  IClassroomDetailed,
  IClassroomEssentials,
  IPartTimeSessionsClassDetailed,
  IProgramClassrooms,
  ISelfBreakClassDetailed,
} from "../types/IClassroomSetup.interface";

export const initCenterSetupForm = {
  centerName: "",
  centerPhoneNumber: "",
  country: "USA",
  state: "",
  city: "",
  zipCode: "",
  address: "",
  operationDays: [""],
  operationStartTime: "",
  operationEndTime: "",
  // totalClassrooms: "",
  // elementarySchool: [{ schoolName: "", uuid: "" }],
  summerProgramStartDate: "",
  summerProgramWeeks: "",
  summerActivitiesName: [""],
};

export const initCenterSetupFormErrors = {
  operationDaysError: "",
  operationStartTimeError: "",
  operationEndTimeError: "",
  totalClassroomsError: "",
};

export const initCenterDetails = {
  centerName: "",
  centerEmail: "",
  zipCode: "",
  address: "",
  organizationStatus: true,
};

export const initClassSetupForm = {
  classroomName: "",
  nickName: "",
  licenseCapacity: 0,
  physicalCapacity: 0,
  studentTeacherRatio: 0,
  sessionType: "",
  agedBAProgram: false,
  ageRangeMinYears: 0,
  ageRangeMinMonths: 0,
  ageRangeMinDays: 0,
  ageRangeMinInDays: 0,
  ageRangeMaxYears: 0,
  ageRangeMaxMonths: 0,
  ageRangeMaxDays: 0,
  ageRangeMaxInDays: 0,
  outSideAgeRangeMin: 0,
  outSideAgeRangeMax: 0,
  selfBreaking: false,
  selfBreakingEndTime: "",
  selfBreakingStartTime: "",
  transitionYears: 0,
  transitionMonths: 0,
  transitionDays: 0,
  transitionInDays: 0,
  transitionClassroom: "",
  ptSessions: [
    {
      ptName: "",
      ptStartTime: null as string | null,
      ptEndTime: null as string | null,
      ptClassTransition: "",
      ptUuid: "",
    },
  ],
};

export const initialClassroomSetupFormErrors = {
  classroomName: null,
  nickName: null,
  licenseCapacity: null,
  physicalCapacity: null,
  studentTeacherRatio: null,
  sessionType: null,
  outSideAgeRangeMin: null,
  outSideAgeRangeMax: null,
  ageRangeMin: null,
  ageRangeMax: null,
  transitionAge: null,
  transitionClassroom: null,
  ptSessions: {},
};

export const initStudentSetupForm = {
  profilePictureUrl: "",
  studentFirstName: "",
  studentLastName: "",
  studentDOB: "",
  studentStatus: "",
  studentElementarySchool: "",
  studentSAType: SchoolAgeProgram.NA,
  studentJoiningDate: "",
  studentDateWaitlisted: "",
  studentAnticipatedLeaveDate: "",
  studentTourDate: "",

  parentInfo: [
    {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      state: "",
      city: "",
      street: "",
      zipCode: "",
      emergencyContact: false,
      livesWith: false,
      canPickUp: false,
    },
  ],
  additionalContact: [
    {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      emergencyContact: false,
      livesWith: false,
      canPickUp: false,
    },
  ],
  classroomId: "",
  classroomSlotType: "",
  classroomTimeSlot: "",
  slotDays: [""],

  transitions: {
    transition1: {
      defaultClassroom: "",
      defaultFullOrPartTime: "",
      defaultDate: "",
      modifyClassroom: "",
      modifyFullOrPartTime: "",
      modifyDate: "",
      ageCalculated: "",
      dateDifference: "",
    },
    transition2: {
      defaultClassroom: "",
      defaultFullOrPartTime: "",
      defaultDate: "",
      modifyClassroom: "",
      modifyFullOrPartTime: "",
      modifyDate: "",
      ageCalculated: "",
      dateDifference: "",
    },
    transition3: {
      defaultClassroom: "",
      defaultFullOrPartTime: "",
      defaultDate: "",
      modifyClassroom: "",
      modifyFullOrPartTime: "",
      modifyDate: "",
      ageCalculated: "",
      dateDifference: "",
    },
  },
  temporaryAbsences: [{ startDate: "", endDate: "" }],
  summerProgramWeeks: [
    {
      weekNumber: null as number | null,
      weekStartDate: "",
      attending: false,
    },
  ],
  summerProgramActivities: "",
};

export const initStudentSetupFormErrors = {
  studentFirstNameError: "",
  studentLastNameError: "",
  studentDOBError: "",
  studentStatusError: "",
  studentJoiningDateError: "",

  parentInfoError: [
    {
      firstNameError: "",
      lastNameError: "",
      emailError: "",
      phoneNumberError: "",
      stateError: "",
      cityError: "",
      streetError: "",
      zipCodeError: "",
    },
  ],
  additionalContactError: [
    {
      firstNameError: "",
      lastNameError: "",
      emailError: "",
      phoneNumberError: "",
    },
  ],
  classroomIdError: "",
  classroomTimeSlotError: "",
  slotDaysError: "",

  temporaryAbsencesError: [{ startDateError: "", endDateError: "" }],
};

export const initEssentialInfo: IClassroomEssentials = {
  classroomName: "",
  minAge: {
    years: null,
    months: null,
    days: null,
    ageInDays: null,
  },
  maxAge: {
    years: null,
    months: null,
    days: null,
    ageInDays: null,
  },
};

// Classroom detailed
export const initBasicInfo: IBasicInfoClassDetailed = {
  nickName: "",
  licenseCapacity: 0,
  physicalCapacity: 0,
  stuTeachRatio: 1,
  classroomSession: SessionType.FullTimeOnly,
  schoolAgeBAProgram: false,
};

export const initPartTimeSession: IPartTimeSessionsClassDetailed = {
  name: "",
  startTime: "",
  endTime: "",
  transition: "",
};

export const initPartTimeSessionsBAProgram: IPartTimeSessionsClassDetailed[] = [
  {
    name: PartTimeSlotNames.BEFORE_SCHOOL,
    startTime: "",
    endTime: "",
    transition: Slot.FULL_TIME,
    uuid: "",
  },
  {
    name: PartTimeSlotNames.AFTER_SCHOOL,
    startTime: "",
    endTime: "",
    transition: Slot.FULL_TIME,
    uuid: "",
  },
  {
    name: PartTimeSlotNames.BEFORE_AFTER_SCHOOL,
    startTime: "",
    endTime: "",
    transition: Slot.FULL_TIME,
    uuid: "",
  },
];

export const initSelfBreak: ISelfBreakClassDetailed = {
  allowed: false,
  startTime: "",
  endTime: "",
};

export const initClassroomDetailed: IClassroomDetailed = {
  basicInfo: initBasicInfo,
  partTimeSessions: [...initPartTimeSessionsBAProgram],
  selfBreak: initSelfBreak,
  ageRange: {
    minimumAge: {
      years: 0,
      months: 0,
      days: 0,
      ageInDays: 0,
      countAllowedOutsideRange: 0,
    },
    maximumAge: {
      years: 0,
      months: 0,
      days: 0,
      ageInDays: 0,
      countAllowedOutsideRange: 0,
    },
  },
};

export const initPartTimeSessionsError = {
  nameError: "",
  startTimeError: "",
  endTimeError: "",
  transitionToError: "",
};

export const initClassroomDetailedFormErrors = {
  basicInfoError: {
    licenseCapacityError: "",
    physicalCapacityError: "",
    stuTeachRatioError: "",
  },
  partTimeSessionsError: [initPartTimeSessionsError],
  selfBreakError: {
    allowedError: "",
    startTimeError: "",
    endTimeError: "",
  },
};
export const initPartTimeSessionEssentials = {
  name: "",
  startTime: "",
  endTime: "",
  transition: Slot.FULL_TIME,
  uuid: "",
};

export const initBAProgramInfo = {
  numberOfClassrooms: 0,
  countySummerStartDate: null as string | null,
  countySummerEndDate: null as string | null,
  partTimeSlots: initPartTimeSessionsBAProgram,
  classrooms: [] as IProgramClassrooms[],
  elementarySchools: [] as IElementarySchool[],
};
export const initSummerProgram = {
  summerStartDate: "",
  summerEndDate: null as string | null,
  numberOfWeeks: null as number | null,
  activities: [] as string[],
  numberOfClassrooms: 0,
  classrooms: [] as IProgramClassrooms[],
};
