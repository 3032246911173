export enum USER_ROLES {
    PARENT = 'parent',
    CENTER = 'center super admin',
    TEACHER = 'teacher'
}

export enum STORAGE_KEYS {
    ACC_TOKEN = "accToken",
    RF_TOKEN = "rfToken",
    USER = "user",
    REMEMBER_ME = "rememberMe",
    PATH_NAME = "pathName",
    ROLE = "role",
    NAVIGATED = "navigated",
    SELECTED_TAB = "selectedTab",
    FILLED_INFO_TEACHERS = "filledInfoTeachers",
    FILLED_INFO_CENTER = "filledInfoCenter",
};


export enum SubscriptionStatus {
    INCOMPLETE = 'incomplete',
    INCOMPLETE_EXPIRED = 'incomplete_expired',
    TRIALING = 'trialing',
    ACTIVE = 'active',
    PAST_DUE = 'past_due',
    CANCELED = 'canceled',
    UNPAID = 'unpaid',
    PAUSED = 'paused',
  }
  