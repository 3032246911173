import React, { useMemo } from "react";
import ApexCharts from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import moment from "moment";

interface RegistrationChartProps {
  dayData: any[];
  title: string;
  barcolor: string;
  linecolor: string;
}

const RegistrationChartCustom: React.FC<RegistrationChartProps> = ({
  dayData,
  title,
  barcolor,
  linecolor,
}) => {
  const { barData, lineData, labels } = useMemo(() => {
    const barData: number[] = [];
    const lineData: number[] = [];
    const labels: string[] = [];

    dayData?.forEach((data) => {
      const dailyValue =
        title === "Center Registration"
          ? data.dailyRegistrations.centers
          : title === "Teacher Registration"
          ? data.dailyRegistrations.teachers
          : data.dailyRegistrations.parents;

      const totalValue =
        title === "Center Registration"
          ? data.totalRegistrationsTillDay.centers
          : title === "Teacher Registration"
          ? data.totalRegistrationsTillDay.teachers
          : data.totalRegistrationsTillDay.parents;

      barData.push(dailyValue);
      lineData.push(totalValue);
      labels.push(data.date);
    });

    return { barData, lineData, labels };
  }, [dayData, title]);

  // Function to adjust x-axis labels based on the date range
  const adjustXAxisLabels = (labels: string[]) => {
    const totalDays = labels.length;

    if (totalDays <= 60) {
      // Show every day
      return labels;
    } else if (totalDays <= 150) {
      // Show every 7th day
      return labels.map((label, index) =>
        (index + 1) % 7 === 0 ? moment(label).format("MMM DD") : ""
      );
    } else if (totalDays <= 365) {
      // Show every 14th day
      return labels.map((label, index) =>
        (index + 1) % 14 === 0 ? moment(label).format("MMM DD") : ""
      );
    } else {
      // Show every 30th day
      return labels.map((label, index) =>
        (index + 1) % 30 === 0 ? moment(label).format("MMM DD") : ""
      );
    }
  };

  const adjustedLabels = adjustXAxisLabels(labels);

  const options: ApexOptions = useMemo(() => {
    return {
      series: [
        {
          name: "Daily Registrations",
          type: "column",
          data: barData,
        },
        {
          name: "Total Registrations",
          type: "line",
          data: lineData,
        },
      ],
      chart: {
        height: 300,
        type: "line",
        toolbar: {
          show: false,
        },
      },
      stroke: {
        width: [0, 4],
        curve: "smooth",
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 5,
        colors: [linecolor],
        strokeColor: linecolor,
        hover: {
          size: 8,
        },
      },
      tooltip: {
        enabled: true,
        shared: false,
        intersect: true,
        y: {
          formatter: (value: number) => `${value} Registrations`,
        },
      },
      labels: adjustedLabels,
      xaxis: {
        categories: adjustedLabels,
        title: {
          text: "Date",
        },
        labels: {
          formatter: (value: string) => (value ? value : ""), // Hide empty labels
        },
      },
      yaxis: [
        {
          title: {
            text: "Registrations",
          },
        },
        {
          opposite: true,
          title: {
            text: "Cumulative Registrations",
          },
        },
      ],
      legend: {
        show: false,
      },
      colors: [barcolor, linecolor],
    };
  }, [barData, lineData, adjustedLabels, barcolor, linecolor]);

  return (
    <div id="chart" className="bg-white rounded-xl p-4">
      <h4 className="text-xl font-semibold text-secondary mb-4">{title}</h4>
      <ApexCharts
        options={options}
        series={options.series}
        type="line"
        height={350}
      />
    </div>
  );
};

export default RegistrationChartCustom;
