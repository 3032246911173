import React, { useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import ClassroomDetailedListTable from "../tables/ClassroomDetailedListTable";
import { useQuery } from "@tanstack/react-query";
import { IClassroomSetup } from "../../types/IClassroomSetup.interface";
import { classroomSvc } from "../../services/classroom.service";
import { useLocation, useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import { forayRoutes } from "../../routes/routes";

interface IProps {
  setHasClassroomWithUnfilledDetails: React.Dispatch<React.SetStateAction<boolean>>;
}

const ClassroomDetailedList: React.FC<IProps> = ({ setHasClassroomWithUnfilledDetails }) => {
  const [classrooms, setClassrooms] = useState<IClassroomSetup[]>([])
  const [fetchClassroomsAgain, setFetchClassroomAgain] = useState(false)
  const [searchQuery, setSearchQuery] = useState<string>("");
  const navigate = useNavigate();
  const location = useLocation()

  // const { data: classrooms } = useQuery<IClassroomSetup[]>({
  //   queryKey: ["classrooms", location?.pathname],
  //   queryFn: async () => {
  //     const res = await classroomSvc.fetchClassrooms();
  //     if (res?.ok) {
  //       return res.data?.existingClassroomSetups;
  //     }
  //     return [];
  //   },
  //   staleTime: 0,
  //   retryOnMount: true
  // });


  const getClassrooms = async () => {
    const res = await classroomSvc.fetchClassrooms()
    if (res?.ok) {
      setClassrooms(res.data?.existingClassroomSetups)
      const withoutDetailClassroom = res.data?.existingClassroomSetups?.find(
        (classroom: IClassroomSetup) =>
          !classroom?.nickName ||
          !classroom?.capacity?.licenseCapacity ||
          !classroom?.capacity?.physicalCapacity ||
          !classroom?.capacity?.studentPerTeacher ||
          !classroom?.settingType
      );
      if (withoutDetailClassroom) setHasClassroomWithUnfilledDetails(true)
      else setHasClassroomWithUnfilledDetails(false)
    }
  }

  useEffect(() => {
    getClassrooms()
  }, [fetchClassroomsAgain])

  const filteredClassrooms = searchQuery ? classrooms?.filter((classroom) =>
    classroom?.classroomName?.toLowerCase().includes(searchQuery.toLowerCase())
    || classroom?.nickName?.toLowerCase().includes(searchQuery.toLowerCase())
  ) : classrooms;


  const handleAddClassroomDetail = () => {
    const withoutDetailClassroom = classrooms?.find(
      (classroom) =>
        !classroom?.nickName ||
        !classroom?.capacity?.licenseCapacity ||
        !classroom?.capacity?.physicalCapacity ||
        !classroom?.capacity?.studentPerTeacher ||
        !classroom?.settingType
    );

    navigate(forayRoutes.detailed_classroom_setup, { state: { classroomId: withoutDetailClassroom?._id } })
  };


  return (
    <>
      <div>
        <div className="bg-white p-6 rounded-xl flex items-center mb-6 gap-6">
        <div>
                <img
                  className="max-w-[126px] max-h-[126px] rounded-full"
                  src="https://a360csastorage.blob.core.windows.net/childcare/515fa21f-2483-4189-87c7-5748a74233ee-image%20(1).jpeg"
                  alt="miley"
                />
              </div>
              <div className="flex-1">
              <p className="text-base text-secondary max-w-[890px]">
                <strong className="block text-lg font-semibold text-secondary">Hi, I'm Miley.</strong> 
                <strong className="text-primary uppercase font-bold">Congratulations!</strong> You have
                completed Classroom-Essentials! <br /> Now, lets add Classroom-Details
                information one by one.
              </p>
              </div>
        </div>
        <div className="bg-white p-3 rounded-xl flex items-center justify-between mb-6">
          <h2 className="text-2xl text-secondary font-semibold">
            Classroom-Details
          </h2>
          <div className="relative">
            <input
              type="search"
              placeholder="Search Classroom"
              className="border-[#CED2DA] border rounded-lg py-2 ps-3 pe-8"
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <span className="absolute right-2 top-3">
              <CiSearch className="w-5 h-5 text-secondaryVariant2" />
            </span>
          </div>
        </div>
        <ClassroomDetailedListTable classrooms={filteredClassrooms as IClassroomSetup[]} handleAddClassroomDetail={handleAddClassroomDetail} fetchAgain={fetchClassroomsAgain} setFetchAgain={setFetchClassroomAgain}/>

      </div>
    </>
  );
};

export default ClassroomDetailedList;
