import moment from "moment";

export const formatDateSlash = (date: string | Date) => {
  return moment(date).format("MM/DD/YY");
};
export const formatDateSlashFullYear = (date: string | Date) => {
  if (!date) return undefined;
  return moment(date).format("MM/DD/YYYY");
};

export const calculateAge = (birthDate: string) => {
  const start = moment(birthDate);
  const end = moment(); // Current date

  const years = end.diff(start, "years");
  start.add(years, "years");

  const months = end.diff(start, "months");
  start.add(months, "months");

  const days = end.diff(start, "days");

  return `${years}Y ${months}M ${days}D`;
  return { years, months, days };
};

export const calculateAgeYM = (
  dateOfBirth: string
): { years: number; months: number } => {
  const dob = new Date(dateOfBirth);
  const today = new Date();

  let years = today.getFullYear() - dob.getFullYear();
  let months = today.getMonth() - dob.getMonth();

  // Adjust for when the current month is before the birth month
  if (months < 0) {
    years--;
    months += 12; // Correct the month count
  }

  // Adjust for when the current day is before the birth day
  if (today.getDate() < dob.getDate()) {
    months--; // Reduce the month count
    if (months < 0) {
      years--; // If months goes below 0, decrement years
      months += 12; // Correct the month count
    }
  }

  return { years, months };
};

export const formatDateHyphenFullYear = (date: string | Date) => {
  return moment(date).format("MM-DD-YYYY");
};
export const formatDateSpace = (date: string | Date) => {
  if (!date) return "";
  return moment(date).format("DD MMMM YYYY") || "";
};

export const formatDateYearFirst = (date: string | Date) => {
  if (!date) return "";
  return moment(date).format("YYYY-MM-DD") || "";
};
export const formatDateTimeFull = (date: string | Date) => {
  return moment(date).format("MMMM, Do YYYY. hh:mma");
};
export const formatDateFull = (date: string | Date) => {
  return moment(date).format("MMMM, Do YYYY");
};
export const formatDateAndTime = (date: string | Date) => {
  if (!date) return { date: "", timeSlot: "" };
  const parsedDate = moment(date, "ddd MMM DD YYYY/h:mma");
  return {
    date: parsedDate.format("YYYY-MM-DD"),
    timeSlot: parsedDate.format("hh:mma").toUpperCase(),
  };
};

export const formatDateAmerican = (date: string | Date) => {
  if (!date) return "";
  return moment(date).format("MMMM DD, YYYY") || "";
};

export const revertToDateType = (date: string) => {
  if (!date) return null;
  return moment(date).toDate();
};
