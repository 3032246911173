import {
  IClassroomSetupPayload,
  IClassroomsPrograms,
  IEssentialClassroomSetup,
  IGetAgeRangeInDaysPayload,
} from "../types/IClassroomSetup.interface";
import { apiForay } from "../utils/axios";

const createEssentialClassrooms = async (
  reqBody: IEssentialClassroomSetup,
  ageSequential?: boolean
) => {
  try {
    const res: any = await apiForay.post(
      `/classroom-setup/${ageSequential}`,
      reqBody
    );
    if (res?.ok) {
      return { data: res?.data, ok: true };
    }
    return {
      ok: false,
      error: res?.data,
    };
  } catch (err) {
    console.log("error: ", err);
  }
};
const createClassroomsPrograms = async (
  reqBody: IClassroomsPrograms,
) => {
  try {
    const res: any = await apiForay.post(
      `/classroom-setup/create-special-classrooms`,
      reqBody
    );
    if (res?.ok) {
      return { data: res?.data, ok: true };
    }
    return {
      ok: false,
      error: res?.data,
    };
  } catch (err) {
    console.log("error: ", err);
  }
};

const updateClassroomSetup = async (
  id: string,
  reqBody: IClassroomSetupPayload
) => {
  try {
    const res: any = await apiForay.patch(`/classroom-setup/${id}`, reqBody);
    if (res?.ok) {
      return { data: res?.data, ok: true };
    }
    return {
      ok: false,
      error: res?.data,
    };
  } catch (err) {
    console.log("error: ", err);
  }
};
const fetchClassrooms = async (filters?: any) => {
  try {
    const res: any = await apiForay.get(`/classroom-setup?`, filters);
    if (res?.ok) {
      return { data: res?.data, ok: true };
    }
    throw new Error("No classroom found");
  } catch (err) {
    console.log("error: ", err);
  }
};
const fetchTransitionSlots = async (classId: string) => {
  try {
    const res: any = await apiForay.get(
      `/classroom-setup/transitionsAvailable/${classId}`
    );
    if (res?.ok) {
      return { data: res?.data, ok: true };
    }
    throw new Error("No classroom found");
  } catch (err) {
    console.log("error: ", err);
  }
};

const fetchAgeRangeInDays = async (reqBody: IGetAgeRangeInDaysPayload) => {
  try {
    const res: any = await apiForay.patch(`/classroom-setup/ageRange`, reqBody);
    if (res?.ok) {
      return { data: res?.data, ok: true };
    }
    throw new Error("Couldn't get age in days");
  } catch (err) {
    console.log("error: ", err);
  }
};
const archiveClassroom = async (id: string, action: boolean) => {
  try {
    const res: any = await apiForay.patch(
      `/student-setup/archiveUnarchiveClassroom/${id}/${action}`
    );
    if (res?.ok) {
      return { data: res?.data, ok: true };
    } else if (!res?.ok && res?.data?.message) {
      return { message: res?.data?.message, ok: false }
    }
    throw new Error("Couldn't get age in days");
  } catch (err) {
    console.log("error: ", err);
  }
};
const archiveSpecialClassroom = async (id: string, action: boolean, reqBody: { numberOfSummerClassrooms: number, numberOfBAClassrooms: number }) => {
  try {
    const res: any = await apiForay.patch(
      `/student-setup/archiveUnarchiveSpecialClassroom/${id}/${action}`, reqBody
    );
    if (res?.ok) {
      return { data: res?.data, ok: true };
    }
    throw new Error("Couldn't get age in days");
  } catch (err) {
    console.log("error: ", err);
  }
};

export const classroomSvc = {
  createEssentialClassrooms,
  createClassroomsPrograms,
  updateClassroomSetup,
  fetchClassrooms,
  fetchTransitionSlots,
  fetchAgeRangeInDays,
  archiveClassroom,
  archiveSpecialClassroom,
};
