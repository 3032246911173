import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { LuCalendarPlus } from "react-icons/lu";

interface DateRangeSelectorProps {
  setIsDialogOpen: (open: boolean) => void;
  startDate: any;
  endDate: any;
  setStartDate: (date: Date | null) => void;
  setEndDate: (date: Date | null) => void;
}

const DateRangeSelector: React.FC<DateRangeSelectorProps> = ({
  setIsDialogOpen,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}) => {
  // const [startDate, setStartDate] = useState<Date | null>(new Date());
  // const [endDate, setEndDate] = useState<Date | null>(null);

  const onChange = (dates: [Date | null, Date | null] | null) => {
    if (dates) {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    }
  };


  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[9999]">
      <div className="bg-white p-6 rounded-xl shadow-lg max-w-[378px]">
        <div className="mb-4 grid grid-cols-2 gap-4">
          <div>
            <label className="block text-[#637083] text-sm">From</label>
            <div className="relative">
              <input
                type="text"
                className="w-full text-[#637083] pr-3 pl-12 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={startDate ? startDate.toLocaleDateString() : ""}
                readOnly
              />
              <LuCalendarPlus
                size={20}
                className="absolute text-[#637083] top-1/2 -translate-x-1/2 -translate-y-1/2  left-[20px] "
              />
            </div>
          </div>
          <div>
            <label className="block text-[#637083] text-sm">To</label>
            <div className="relative">
              <input
                type="text"
                className="w-full text-[#637083] pr-3 pl-12 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={endDate ? endDate.toLocaleDateString() : ""}
                readOnly
              />
              <LuCalendarPlus
                size={20}
                className="absolute text-[#637083] top-1/2 -translate-x-1/2 -translate-y-1/2  left-[20px] "
              />
            </div>
          </div>
        </div>
        <div className="custom-picker">
          <DatePicker
            selected={startDate}
            onChange={onChange}
            startDate={startDate || undefined}
            endDate={endDate || undefined}
            selectsRange
            inline
          />
        </div>

        <div className="mt-4 flex gap-3 ">
          <button
            className="px-4 py-2 bg-primary text-white rounded-md"
            onClick={() => setIsDialogOpen(false)}
          >
            Apply
          </button>
          <button
            className="px-4 py-2 bg-white border border-secondary text-secondary rounded-md mr-2"
            onClick={() => setIsDialogOpen(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default DateRangeSelector;
